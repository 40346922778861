import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'
import {useAuth} from 'services/auth'

import {serviceDomainService} from '../serviceDomainService'

export function useGetCategorias() {
  return useQuery({
    queryKey: [QueryKeys.GetCategorias],
    queryFn: () => {
      return serviceDomainService.getCategorias()
    },
    retry: false,
    staleTime: Infinity,
    enabled: true,
  })
}
