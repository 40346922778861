import {ChangeEvent, ReactNode} from 'react'

import {Box, InputAdornment, TextField} from '@mui/material'

interface ITextFieldComponent {
  label: string
  error: string
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  type: string
  value: string
  children: ReactNode
  placeholder: string
}
const TextFieldComponent = ({
  label,
  error,
  handleChange,
  type,
  children,
  value,
  placeholder,
}: ITextFieldComponent) => {
  return (
    <Box className="flex flex-col gap-[5px] w-full max-w-[">
      <p className="text-black text-[15px]">{label}</p>
      <TextField
        variant="outlined"
        error={!!error}
        onChange={handleChange}
        type={type}
        value={value}
        className="h-[48px]"
        color="error"
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{children}</InputAdornment>
          ),
          sx: {
            background: '#fff', // Defina a cor de fundo desejada aqui
          },
        }}
      />
    </Box>
  )
}

export default TextFieldComponent
