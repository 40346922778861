// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_table__U-5NH {
  width: 100%;
  min-width: 1300px;
}

.table_table__U-5NH thead tr th:nth-child(1) {
  border-top-left-radius: 10px;
}
.table_table__U-5NH thead tr th:last-child {
  border-top-right-radius: 10px;
}

.table_table__U-5NH thead tr th {
  background: #f3f4f6;
  padding: 20px;
}

.table_table__U-5NH tbody tr:nth-child(even) {
  background: #f3f4f6;
}

.table_table__U-5NH tbody tr td {
  padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".table {\n  width: 100%;\n  min-width: 1300px;\n}\n\n.table thead tr th:nth-child(1) {\n  border-top-left-radius: 10px;\n}\n.table thead tr th:last-child {\n  border-top-right-radius: 10px;\n}\n\n.table thead tr th {\n  background: #f3f4f6;\n  padding: 20px;\n}\n\n.table tbody tr:nth-child(even) {\n  background: #f3f4f6;\n}\n\n.table tbody tr td {\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `table_table__U-5NH`
};
export default ___CSS_LOADER_EXPORT___;
