import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'
import {IGetDetalhesDaSolicitacaoRequest} from '../serviceDomainTypes'

export const useGetDetalhesDaSolicitacao = ({
  solicitacaoId,
}: IGetDetalhesDaSolicitacaoRequest) => {
  return useQuery({
    queryKey: [QueryKeys.GetDetalhesDaSolicitacao],
    queryFn: () => {
      return serviceDomainService.getDetalhesDaSolicitacao({solicitacaoId})
    },
  })
}
