/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {If} from 'components/atoms/If'
import Card from 'components/Card'
import Footer from 'components/molecules/footer/Footer'
import Header from 'components/molecules/header/header'
import {
  Categoria,
  IGetServicesResponse,
  useDeleteFavoritar,
  useGetServices,
  usePostFavoritar,
} from 'domains/ServiceDomain'
import {useGetServiceCategory} from 'domains/ServiceDomain/useCases/useGetServiceCategory'
import {BsSend} from 'react-icons/bs'
import {FaHome, FaRegBookmark} from 'react-icons/fa'
import {IoHomeOutline} from 'react-icons/io5'
import {useNavigate} from 'react-router'

const initialValueSeeMore = {
  favorites: false,
  featured: false,
  all: false,
  category: false,
}

const Home = () => {
  const navigate = useNavigate()

  const {data: categories, isLoading: isLoadingCategories} =
    useGetServiceCategory()
  const {postFavoritar} = usePostFavoritar()
  const {deleteFavoritar} = useDeleteFavoritar()

  const [cards, setCards] = useState<IGetServicesResponse[]>([])
  const [cardClicked, setCardClicked] = useState<
    Array<{id: number; servico: string}>
  >([])
  const [isLoadingAll, setIsLoadingAll] = useState(true)
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false)

  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [selectedCategory, setSelectedCategory] = useState<Categoria | null>(
    null,
  )
  const {
    data: services,
    isLoading,
    isSuccess,
  } = useGetServices({
    identificador:
      selectedCategory && selectedCategory.name !== 'Favoritos'
        ? selectedCategory.name
        : '',
    favorito: selectedCategory ? selectedCategory.name === 'Favoritos' : '',
    nome: '',
  })
  const [seeMore, setSeeMo] = useState(initialValueSeeMore)

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    category: Categoria,
  ) => {
    setSelectedIndex(index)
    setSelectedCategory(category)
    setSeeMo(initialValueSeeMore)
  }

  const favoriteCards = useMemo(() => {
    const favorites = cards.filter((service) => service.favorito) || []
    setIsLoadingFavorite(false)
    return seeMore.favorites ? favorites : favorites.slice(0, 6)
  }, [cards, seeMore])

  const servicosDestaque = useMemo(() => {
    if (cards.length) {
      const ordenadosPorDestaque = cards.sort(
        (a, b) => b.contadorDestaque - a.contadorDestaque,
      )
      return seeMore.featured
        ? ordenadosPorDestaque
        : ordenadosPorDestaque.slice(0, 6)
    }
    setIsLoadingFavorite(false)
    return []
  }, [cards, seeMore])

  const allCards = useMemo(() => {
    setIsLoadingFavorite(false)
    return seeMore.all ? cards : cards.slice(0, 6)
  }, [cards, seeMore])

  const categoryCards = useMemo(() => {
    return seeMore.category ? cards : cards.slice(0, 6)
  }, [cards, seeMore])

  const onSeeMore = (name: keyof typeof seeMore) => {
    setSeeMo((old) => ({
      ...old,
      [name]: true,
    }))
  }

  const toggleFavorite = (id: number, servico: string, isFavorite: boolean) => {
    setCardClicked((prev) => {
      const array = [...prev]
      array.push({id, servico})
      return array
    })
    setIsLoadingFavorite(true)
    if (isFavorite) {
      deleteFavoritar({
        servico,
      })
    } else {
      postFavoritar({
        servico,
      })
    }
  }

  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {value} = e.target
    const updatedCards =
      services?.filter(
        (card) =>
          card.name.toLowerCase().includes(value.toLowerCase()) ||
          card.identificadorProjeto.toLowerCase().includes(value.toLowerCase()),
      ) || []
    setCards(updatedCards ?? [])
    setSeeMo(initialValueSeeMore)
  }

  const HandleClickCard = (idSolicitacao: Number) => {
    navigate(`/carta-de-servicos/abrir-solicitacao/${idSolicitacao}`)
  }

  useEffect(() => {
    if (!isLoading) setCardClicked([])
  }, [isLoading])

  useEffect(() => {
    if (isSuccess && services && !isLoadingCategories) {
      setCards(services)
      setIsLoadingAll(false)
    }
  }, [isSuccess, services, isLoadingCategories])

  return (
    <>
      <Box className="mb-[200px]">
        <Header handleSearchChange={handleSearchChange} />
        {isLoadingAll ? (
          <p className="h-[100vh] flex justify-center mt-[80px]">
            Carregando...
          </p>
        ) : (
          <Box className="w-full mt-[50px] flex items-baseline justify-center gap-[20px] p-[15px]">
            <Box className="w-full max-w-[370px] border-[1px] border-[#ccc] rounded-md">
              <p className="h-[60px] text-[20px] rounded-tl-md rounded-tr-md flex justify-start pl-5 font-bold items-center bg-[#004394] text-white">
                CATEGORIAS
              </p>
              <List
                component="nav"
                aria-label="main mailbox folders"
                className="p-[15px]">
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={() => {
                    setSelectedCategory(null)
                    setSelectedIndex(0)
                  }}>
                  <ListItemIcon>
                    <FaHome color="black" size={24} />
                  </ListItemIcon>
                  <ListItemText primary="Todos os Serviços" />
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={(event) =>
                    handleListItemClick(event, 1, {
                      icon: {content: '', contentType: '', fileName: ''},
                      name: 'Favoritos',
                    })
                  }>
                  <ListItemIcon>
                    <FaRegBookmark
                      color="black"
                      className="ml-[1px] text-center"
                      size={24}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Favoritos" />
                </ListItemButton>
                <Divider className="m-[10px]" />
                {categories?.map((category, index) => {
                  return (
                    <ListItemButton
                      key={index}
                      selected={selectedIndex === index + 2}
                      onClick={(event) =>
                        handleListItemClick(event, index + 2, category)
                      }>
                      <ListItemIcon>
                        <img
                          src={`data:${category.icon.contentType};base64, ${category.icon.content}`}
                          width={24}
                          className="ml-[3px]"
                          height={24}
                          alt={category.name}
                        />
                      </ListItemIcon>
                      <ListItemText primary={category.name} />
                    </ListItemButton>
                  )
                })}
              </List>
            </Box>
            <Box
              maxWidth={'800px'}
              className="w-[100%] flex flex-col items-center px-[15px]">
              {!selectedCategory ? (
                <Box className="w-[100%] flex flex-col items-center gap-[20px]">
                  {!!favoriteCards?.length && (
                    <>
                      <Box className="flex flex-col gap-[10px] w-[100%] max-[1217px]:max-w-[770px] max-[817px]:max-w-[500px] justify-center">
                        <p className="text-[28px] font-[700]">
                          SERVIÇOS FAVORITOS
                        </p>
                        <Divider />
                      </Box>
                      <Box className="flex flex-wrap items-center justify-center gap-[30px]">
                        {favoriteCards?.map((service) => (
                          <Card
                            key={service.id}
                            instituicao={service.identificadorProjeto}
                            tipoServico={service.name}
                            descricao={service.description}
                            cardClicked={
                              cardClicked[
                                cardClicked.findIndex(
                                  (value) => value.id === service.id,
                                )
                              ]?.servico
                            }
                            isLoadingFavoritar={isLoadingFavorite}
                            btnSolicitar={() => HandleClickCard(service.id)}
                            isFavorite={service.favorito}
                            toggleFavorite={() =>
                              toggleFavorite(
                                service.id,
                                service.name,
                                service.favorito,
                              )
                            }
                          />
                        ))}
                      </Box>
                      {!seeMore.favorites && (
                        <Box className="mb-[40px] flex justify-center">
                          <Button
                            variant="contained"
                            className="max-w-[251px]"
                            onClick={() => onSeeMore('favorites')}>
                            Ver todos os Serviços
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                  {!!servicosDestaque?.length && (
                    <>
                      <Box className="flex flex-col gap-[10px] w-[100%] max-[1217px]:max-w-[770px] max-[817px]:max-w-[500px] mt-[10px] justify-center">
                        <p className="text-[28px] font-[700]">
                          SERVIÇOS EM DESTAQUE
                        </p>
                        <Divider />
                      </Box>
                      <Box className="flex flex-wrap items-center justify-center gap-[30px]">
                        {servicosDestaque?.map((service) => (
                          <Card
                            key={service.id}
                            instituicao={service.identificadorProjeto}
                            tipoServico={service.name}
                            descricao={service.description}
                            cardClicked={
                              cardClicked[
                                cardClicked.findIndex(
                                  (value) => value.id === service.id,
                                )
                              ]?.servico
                            }
                            btnSolicitar={() => HandleClickCard(service.id)}
                            isFavorite={service.favorito}
                            isLoadingFavoritar={isLoadingFavorite}
                            toggleFavorite={() =>
                              toggleFavorite(
                                service.id,
                                service.name,
                                service.favorito,
                              )
                            }
                          />
                        ))}
                      </Box>

                      {!seeMore.featured && (
                        <Box className="mb-[40px] flex justify-center">
                          <Button
                            variant="contained"
                            className="max-w-[251px]"
                            onClick={() => onSeeMore('featured')}>
                            Ver todos os Serviços
                          </Button>
                        </Box>
                      )}
                    </>
                  )}

                  <Box className="flex flex-col gap-[10px] w-[100%] max-[1217px]:max-w-[770px] max-[817px]:max-w-[500px] mt-[20px] justify-center">
                    <p className="text-[28px] font-[700]">TODOS OS SERVIÇOS</p>
                    <Divider />
                  </Box>
                  <Box className="flex flex-wrap items-center justify-center gap-[30px]">
                    {allCards?.map((service) => (
                      <Card
                        key={service.id}
                        instituicao={service.identificadorProjeto}
                        tipoServico={service.name}
                        descricao={service.description}
                        cardClicked={
                          cardClicked[
                            cardClicked.findIndex(
                              (value) => value.id === service.id,
                            )
                          ]?.servico
                        }
                        isLoadingFavoritar={isLoadingFavorite}
                        btnSolicitar={() => HandleClickCard(service.id)}
                        isFavorite={service.favorito}
                        toggleFavorite={() =>
                          toggleFavorite(
                            service.id,
                            service.name,
                            service.favorito,
                          )
                        }
                      />
                    ))}
                  </Box>

                  {!seeMore.all && (
                    <Box className="mb-[40px] flex justify-center">
                      <Button
                        variant="contained"
                        className="max-w-[251px]"
                        onClick={() => onSeeMore('all')}>
                        Ver todos os Serviços
                      </Button>
                    </Box>
                  )}

                  <If condition={!isLoading && !cards.length}>
                    <p className="text-[28px] font-[700]">
                      Nenhum serviço encontrado
                    </p>
                  </If>
                </Box>
              ) : (
                <Box className="w-[100%] flex flex-col items-center gap-[20px]">
                  <Box className="flex flex-col gap-[10px] w-[100%] max-[1217px]:max-w-[770px] max-[817px]:max-w-[500px] justify-center">
                    <Box className="flex items-center gap-[20px]">
                      {selectedCategory.name === 'Favoritos' ? (
                        <FaRegBookmark style={{fontSize: 28}} />
                      ) : (
                        <img
                          src={`data:${selectedCategory.icon.contentType};base64, ${selectedCategory.icon.content}`}
                          width={28}
                          height={28}
                          alt={selectedCategory.name}
                        />
                      )}
                      <p className="text-[28px] font-[700]">
                        {selectedCategory.name.toUpperCase()}
                      </p>
                    </Box>
                    <Divider />
                  </Box>
                  {categoryCards?.length ? (
                    <>
                      <Box className="flex flex-wrap items-center justify-center gap-[30px]">
                        {categoryCards?.map((service) => (
                          <Card
                            key={service.id}
                            instituicao={service.identificadorProjeto}
                            tipoServico={service.name}
                            descricao={service.description}
                            cardClicked={
                              cardClicked[
                                cardClicked.findIndex(
                                  (value) => value.id === service.id,
                                )
                              ]?.servico
                            }
                            isLoadingFavoritar={isLoadingFavorite}
                            btnSolicitar={() => HandleClickCard(service.id)}
                            isFavorite={service.favorito}
                            toggleFavorite={() =>
                              toggleFavorite(
                                service.id,
                                service.name,
                                service.favorito,
                              )
                            }
                          />
                        ))}
                      </Box>
                      {!seeMore.category && (
                        <Box className="mb-[40px] flex justify-center">
                          <Button
                            variant="contained"
                            className="max-w-[251px]"
                            onClick={() => onSeeMore('category')}>
                            Ver todos os Serviços
                          </Button>
                        </Box>
                      )}
                    </>
                  ) : isLoading ? (
                    <Box className="w-full h-[30px] flex items-center justify-center">
                      <p className="w-full text-center py-[15px] uppercase bg-[#e6f1ff]">
                        Carregando...
                      </p>
                    </Box>
                  ) : (
                    <Box className="w-full h-[30px] flex items-center justify-center">
                      <p className="w-full text-center py-[15px] uppercase bg-[#e6f1ff]">
                        Nenhum serviço a mostrar
                      </p>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Footer />
    </>
  )
}

export default Home
