import {AxiosError} from 'axios'
import {MutationOptions} from 'infra'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

import {serviceDomainService} from '../serviceDomainService'
import {IPutModalComentarioRequest} from '../serviceDomainTypes'

export function usePutModalComentario(options?: MutationOptions<any>) {
  const mutation = useMutation<any, AxiosError, IPutModalComentarioRequest>({
    mutationFn: async (dataPost: IPutModalComentarioRequest) => {
      return await serviceDomainService.putModalComentario(dataPost)
    },
    retry: false,
    onError: (error: any) => {
      toast.error(
        error.message.response.data ?? 'Falha ao registrar uma novo comentário',
      )
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data: any) => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoadingComentario: mutation.isLoading,
    cadastrarComentario: (data: IPutModalComentarioRequest) =>
      mutation.mutate(data),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
