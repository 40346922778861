import {queryClient} from 'config'
import {MutationOptions, QueryKeys} from 'infra'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {useAuth} from 'services/auth'

import {serviceDomainService} from '../serviceDomainService'
import {IPostFavoritoParams} from '../serviceDomainTypes'

export function usePostFavoritar(options?: MutationOptions<any>) {
  const {userData} = useAuth()

  const mutation = useMutation<
    any,
    Error,
    Pick<IPostFavoritoParams, 'servico'>
  >({
    mutationFn: async (params: Pick<IPostFavoritoParams, 'servico'>) => {
      return await serviceDomainService.postFavoritar({
        email: userData.email,
        servico: String(params.servico).toLocaleLowerCase(),
      })
    },
    onError: (error: any) => {
      toast.error(error.message.response.data ?? 'Falha ao favoritar')
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data: any) => {
      queryClient.removeQueries(QueryKeys.GetAllServices)
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    postFavoritar: (variables: Pick<IPostFavoritoParams, 'servico'>) =>
      mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
