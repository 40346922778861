export const If = ({
  condition,
  children,
}: {
  condition: boolean
  children: any
}) => {
  if (!condition) {
    return null
  }

  return children
}
