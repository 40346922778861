import {useContext} from 'react'

import {AuthContext} from './Provider/authProvider'

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('Necessario adicionar o provider')
  }
  return context
}
