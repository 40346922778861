const useGetFirstLetter = () => {
  const getFirstLetter = (name: string): string => {
    if (!name) {
      return ''
    }
    const split = name.split(' ')
    const firstLetter = split[0].charAt(0)
    const firstLetterOfLastName = split[split.length - 1].charAt(0)
    return firstLetter.toUpperCase() + firstLetterOfLastName.toUpperCase()
  }

  return {getFirstLetter}
}

export default useGetFirstLetter
