import React, {HTMLAttributes, forwardRef, useState} from 'react'

import {
  Autocomplete,
  Button,
  FormControl,
  MenuItem,
  TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import {ILotacaoUser} from 'domains/Auth'
import {List, ListRowProps} from 'react-virtualized'
import AutoSizer from 'react-virtualized-auto-sizer'
import {useAuth} from 'services/auth'

import {IconArrowRight} from '../icons/IconArrowRight'

interface ModalProps {
  open: boolean
  handleClose: () => void
}

const defaultStyle: React.CSSProperties = {
  position: 'relative',
  maxWidth: 600,
  backgroundColor: 'background.paper',
  border: '1px solid white',
  boxShadow: '24px',
  borderRadius: '10px',
  padding: 3,
}

export function ModalChangeSchool({open, handleClose}: ModalProps) {
  const {
    userData: {lotacoes},
    lotacaoSelecionada,
    handleChangeLotacao,
  } = useAuth()

  const [value, setValue] = useState<ILotacaoUser>(lotacaoSelecionada)
  const [inputValue, setInputValue] = useState('')
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)
  const [selected, setSelected] = useState<ILotacaoUser | null>(null)

  const handleInputChange = (event: any, value: string) => {
    setInputValue(value)
  }

  const filteredOptions = lotacoes.filter(
    (option) =>
      option.funcao.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.nomeEntidade.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.idEntidade === Number(inputValue) ||
      String(
        option.nomeEntidade.toLowerCase() + ' - ' + option.funcao.toLowerCase(),
      ).includes(inputValue.toLowerCase()),
  )

  const renderRow = ({index, style}: ListRowProps) => {
    const option = filteredOptions[index]
    const handleSelected = () => {
      setValue({...option})
      setSelected(option)
      setAutoCompleteOpen(false)
    }
    return (
      <MenuItem
        key={option.id}
        style={{
          ...style,
          height: style.height,
        }}
        selected={selected === option}
        onClick={handleSelected}>
        <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
          {option.nomeEntidade} - {option.funcao}
        </Typography>
      </MenuItem>
    )
  }

  // eslint-disable-next-line react/display-name
  const ListboxComponent = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLElement>
  >((props, ref) => {
    const {children, ...other} = props

    return (
      <div ref={ref} {...other}>
        <AutoSizer disableHeight>
          {({width}: {width: number}) => (
            <List
              width={width}
              height={
                filteredOptions.length < 4 ? filteredOptions.length * 80 : 250
              }
              rowCount={filteredOptions.length}
              rowHeight={80}
              rowRenderer={renderRow}
              overscanRowCount={5}
            />
          )}
        </AutoSizer>
      </div>
    )
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex justify-center p-[10px] items-center">
      <Box sx={{...defaultStyle, width: '100%'}}>
        <Typography
          id="modal-modal-title"
          sx={{mt: 4, fontSize: 24, fontWeight: 600}}>
          Selecione a GRE/Escola
        </Typography>

        <FormControl style={{width: '100%'}}>
          <Autocomplete
            value={value}
            open={autoCompleteOpen}
            disableListWrap
            onOpen={() => setAutoCompleteOpen(true)}
            onClose={() => setAutoCompleteOpen(false)}
            style={{marginTop: 20}}
            id="selecione-gre"
            options={filteredOptions ?? []}
            ListboxComponent={ListboxComponent}
            freeSolo
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option: any) =>
              option.nomeEntidade + ' - ' + option.funcao
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pesquisar por GRE, Nome da Escola ou por Cargo"
                variant="outlined"
                onChange={(text) => handleInputChange(null, text.target.value)}
              />
            )}
          />
        </FormControl>

        <p className="mt-2 text-[10px]">
          Caso o perfil de usuário tenha mais de uma GRE/Escola, usuário deve
          selecionar a GRE/Escola
        </p>

        <Box className="flex justify-end items-end gap-4 my-4">
          <Button
            style={{maxWidth: 'max-content'}}
            variant="contained"
            endIcon={<IconArrowRight />}
            fullWidth={false}
            disabled={!value.id}
            onClick={() => handleChangeLotacao(Number(value.id))}>
            Avançar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
