import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'
import {useAuth} from 'services/auth'

import {serviceDomainService} from '../serviceDomainService'

export function useGetPendenciasListaDeAprovacao({idGre}: {idGre: string}) {
  return useQuery({
    queryKey: [QueryKeys.GetPendencias],
    queryFn: () => {
      return serviceDomainService.getPendenciasListaDeAprovacao(idGre)
    },
  })
}
