import {ICustomField} from 'domains/ServiceDomain'
import {z} from 'zod'

// Define the accepted file types and maximum upload size
const ACCEPTED_FILE_TYPES: string[] = [
  'image/png',
  'application/pdf',
  'image/jpeg',
  'image/tiff',
]
const ACCEPTED_FILE_TYPES_EXCEL: string[] = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
]
const MAX_UPLOAD_SIZE: number = 1024 * 1024 * 2

// Define the field data interface
interface Field {
  id: number
  name: string
  customizedType: string
  fieldFormat: string
  regexp?: string
  required: boolean
  filter: boolean
  searchable: boolean
  defaultValue?: string
  trackers?: {id: number; name: string}[]
  possibleValues?: {value: string; label: string}[]
  category: string
}

export const generateSchema = (fields: ICustomField[]) => {
  // Ensure fields is an array before proceeding
  if (!Array.isArray(fields)) {
    throw new Error('Fields must be an array.')
  }

  const schemaObject: Record<string, any> = {}
  fields.forEach((field) => {
    if (field.fieldFormat === 'list') {
      schemaObject[field.name] = z
        .string()
        .min(1, 'Este campo é obrigatório')
        .default('')
    } else if (field.fieldFormat === 'attachment') {
      if (field.id === 189 || field.id === 195) {
        schemaObject[field.name] = z
          .any()
          .optional()
          .refine(
            (file: File) => !file || file.size <= MAX_UPLOAD_SIZE,
            'Arquivo deve ser menos de 2MB',
          )
          .refine(
            (file: File) =>
              !file || ACCEPTED_FILE_TYPES_EXCEL.includes(file.type ?? ''),
            'Arquivo deve ser do tipo XLS, XLSM ou PDF',
          )
      } else {
        schemaObject[field.name] = z
          .any()
          .optional()
          .refine(
            (file: File) => !file || file.size <= MAX_UPLOAD_SIZE,
            'Arquivo deve ser menos de 2MB',
          )
          .refine(
            (file: File) =>
              !file || ACCEPTED_FILE_TYPES.includes(file.type ?? ''),
            'Arquivo deve ser pdf, png, jpg, tiff',
          )
      }
    } else {
      if (field.id === 146) {
        schemaObject[field.name] = z
          .string()
          .refine((value) => {
            // Verifica se o valor é uma string numérica
            return /^\d+$/.test(value)
          }, 'Este campo deve ter somente números')
          .refine((value) => {
            // Verifica se a string tem 16 caracteres
            return value.length === 16
          }, 'Este campo deve ter 16 caracteres')
          .default('')
      }
      if (field.id === 154) {
        schemaObject[field.name] = z
          .string()
          .refine((value) => {
            // Verifica se o valor é uma string numérica
            return /^([0-9]{3})\.?([0-9]{3})\.?([0-9]{3})-?([0-9]{2})$/.test(
              value,
            )
          }, 'Digite o CPF no formato correto. Ex.: 999.999.999-99')
          .default('')
      } else {
        schemaObject[`${field.name}`] = z
          .string()
          .min(1, 'Este campo é obrigatório')
          .default('')
      }
    }
  })
  schemaObject.subject = z
    .string()
    .min(1, 'Este campo é obrigatório')
    .default('')
  schemaObject.description = z
    .string()
    .min(1, 'Este campo é obrigatório')
    .default('')
  schemaObject.File = z
    .any()
    .optional()
    .refine((file) => {
      return !file || file.size <= MAX_UPLOAD_SIZE
    }, 'Arquivo deve ser menos de 2MB')
    .refine((file) => {
      return !file || ACCEPTED_FILE_TYPES.includes(file.type ?? '')
    }, 'Arquivo deve ser pdf, png, jpg, tiff')

  return z.object(schemaObject)
}
