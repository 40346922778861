import {InputAdornment} from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextFieldMui, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import {makeStyles} from '@mui/styles'

export const useStyles = makeStyles({
  label: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 16,
    color: '#23292e',
    marginBottom: 10,
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})
export interface ICustomTextFieldProps {
  label: string
  placeholder?: string
  onChange?: (value: string) => void
  startIcon?: React.ReactNode
  fullWidth?: boolean
  ClassName?: number
}

export type TextFieldProps = ICustomTextFieldProps & MuiTextFieldProps

export function TextFieldCom({
  label,
  placeholder,
  onChange,
  value,
  startIcon: StartIcon,
  InputProps,
  fullWidth = true,
  ...textFieldProps
}: TextFieldProps) {
  const classes = useStyles()

  return (
    <Box>
      <FormControl fullWidth={fullWidth}>
        <FormLabel htmlFor={label} className={classes.label}>
          {label}
        </FormLabel>
        <TextFieldMui
          {...textFieldProps}
          placeholder={placeholder ?? ''}
          InputProps={{
            ...InputProps,
            startAdornment: StartIcon ? (
              <InputAdornment position="start">{StartIcon}</InputAdornment>
            ) : undefined,
          }}
          className={classes.input}
          id={label}
          value={value || ''}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      </FormControl>
    </Box>
  )
}
