import React, {useRef} from 'react'

import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import {Box, Button} from '@mui/material'
import classNames from 'classnames'

interface IUpload {
  nameButton: string
  value: string | undefined
  setValue?: (value: any) => void
  handleButton?: () => void
}

const FileInputDownload: React.FC<IUpload> = ({
  nameButton,
  value,
  setValue,
  handleButton,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current && setValue) {
      // Reset the file input value
      fileInputRef.current.value = ''
      const file = fileInputRef.current.files?.[0]
      setValue(file)
    }
    if (handleButton) {
      handleButton()
    }
  }
  return (
    <>
      <Box className="flex flex-wrap gap-[10px]">
        <div className={'relative overflow-hidden grow-[1]'}>
          <input
            type="file"
            className={'top-0 left-0 absolute opacity-0 w-full h-[58px]'}
            disabled
          />
          <div
            className={
              'flex items-center p-[10px] flex-wrap gap-[10px] min-h-[58px] rounded-[6px] bg-[#F3F4F6]'
            }>
            <label
              className={
                'min-h-[34px] min-w-[190px] px-[24px] flex flex-wrap justify-center rounded-[6px] items-center bg-[#E3E3E3]'
              }>
              <div className="text-[#8899A8]">Anexar arquivo</div>
            </label>
            <a
              className={
                'overflow-hidden no-underline cursor-pointer text-ellipsis text-[#8899A8]'
              }
              href={value}
              target="_blank"
              rel="noreferrer">
              {value}
            </a>
          </div>
        </div>

        <Button
          className="w-[180px]"
          variant="contained"
          size="small"
          startIcon={<TaskOutlinedIcon />}
          onClick={handleButtonClick}>
          {nameButton}
        </Button>
      </Box>
    </>
  )
}

export default FileInputDownload
