import {useEffect, useState} from 'react'

import {Box, Breadcrumbs, Button, Divider} from '@mui/material'
import BasicBreadcrumbs from 'components/atoms/bread/breadcrumbs'
import CardHistorico from 'components/atoms/cardHistorico/CardHistorico'
import DisabledFileField from 'components/atoms/disabledFileField/DisabledFileField'
import DisabledSelectField from 'components/atoms/disabledSelectField/DisabledSelectField'
import DisabledTextArea from 'components/atoms/disabledTextArea/DisabledTextArea'
import DisabledTextField from 'components/atoms/disabledTextField/DisabledTextField'
import ModalComentario from 'components/atoms/modalComentario/ModalComentario'
import Stepper from 'components/atoms/stepper/Stepper'
import Navbar from 'components/molecules/navbar/Navbar'
import {IGetFileResponse} from 'domains/ServiceDomain'
import {useGetDetalhesDaSolicitacao} from 'domains/ServiceDomain/useCases/useDetalhesDaSolicitacao'
import {useGetFieldsCustom} from 'domains/ServiceDomain/useCases/useGetFieldsCustom'
import {useGetFile} from 'domains/ServiceDomain/useCases/useGetFile'
import {useGetSolicitacao} from 'domains/ServiceDomain/useCases/useGetSolicitacao'
import {BsDownload} from 'react-icons/bs'
import {FaRegCircleCheck} from 'react-icons/fa6'
import {Link, useParams} from 'react-router-dom'

import {formatDate} from '../minhasSolicitacoes'

const DetalhesDaSolicitacao = () => {
  const [modalComentario, setModalComentario] = useState(false)

  const currentRoute = window.location.pathname
  const parts = currentRoute.split('/')
  const id = parts[parts.length - 1]

  const [rejeitadoId, setRejeitadoId] = useState<number | null>(null)
  const [active, setActive] = useState<number>(0)
  const {
    data: results,
    isLoading,
    refetch,
  } = useGetDetalhesDaSolicitacao({
    solicitacaoId: id ?? '0',
  })
  const {getFile} = useGetFile({
    onSuccess: (data: IGetFileResponse) => {
      const linkSource = `data:${data.contentType};base64,${data.content}`
      const downloadLink = document.createElement('a')
      const fileName = data.fileName

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  })

  const dadosEspecificosDoServico = results?.customFields?.filter(
    (field) => field?.category === 'SERVICO',
  )
  const dadosDeAcompanhamento = results?.customFields?.filter(
    (field) => field?.category === 'ACOMPANHAMENTO',
  )

  const handleCloseWithRefetch = () => {
    setModalComentario(false)
    refetch()
  }

  useEffect(() => {
    if (results) {
      if (results.journals) {
        const resultsFiltrado = results.journals.filter(
          (journal) => journal?.details[0]?.name === 'status_id',
        )
        if (resultsFiltrado) {
          const newValue =
            resultsFiltrado[resultsFiltrado.length - 1]?.details[0].newValue
          const oldValue =
            resultsFiltrado[resultsFiltrado.length - 1]?.details[0].oldValue
          if (newValue && oldValue) {
            if (newValue === '28' || newValue === '29') {
              setRejeitadoId(Number(newValue))
              setActive(Number(oldValue))
            } else {
              setRejeitadoId(null)
              setActive(results.status.id)
            }
          } else {
            setRejeitadoId(null)
            setActive(results.status.id)
          }
        }
      }
    }
    window.scrollTo(0, 0)
  }, [results])

  const {data: solicitacao, isSuccess} = useGetSolicitacao(id)

  const {data: customfields} = useGetFieldsCustom({
    idServico: String(solicitacao?.tracker.id),
    enabled: isSuccess,
  })

  return (
    <>
      <Navbar />
      <Box className="w-full flex flex-col items-center mb-[180px]">
        <Box className="fixed max-[600px]:h-[160px] h-[120px] w-full bg-[white] z-[999] flex flex-col items-center  justify-center">
          <Box
            maxWidth={'xl'}
            className="w-full flex gap-[10px] flex-col z-[999] justify-center px-[20px]">
            <Breadcrumbs>
              <Link
                className="hover:underline"
                color="inherit"
                to="/carta-de-servicos">
                Carta de Serviços
              </Link>
              <Link
                className="hover:underline"
                color="inherit"
                to="/carta-de-servicos/minhas-solicitacoes">
                Minhas Solicitações
              </Link>
              <p className="text-[#004394]" color="inherit">
                Detalhes da Solicitação
              </p>
            </Breadcrumbs>
            <Divider />
            <p className="font-[700] text-[28px]">Detalhes da Solicitação</p>
            <Divider />
          </Box>
        </Box>
        <Box
          maxWidth={'xl'}
          className="w-full py-[30px] max-[600px]:mt-[160px] mt-[120px] px-[20px] flex flex-col gap-[30px]">
          {isLoading ? (
            'Carregando...'
          ) : (
            <>
              <Box className="overflow-auto">
                <Box className="flex flex-col mb-[20px] justify-center min-w-[1300px]">
                  <Stepper active={active} rejeitadoId={rejeitadoId} />
                </Box>
              </Box>
              <Box className="flex flex-col gap-[20px]">
                <Box className="flex flex-col gap-[20px] border-[1px] border-[#ccc] p-[20px] rounded-[6px]">
                  <p className="font-bold text-[28px]">Solicitação</p>
                  <Box className="flex flex-wrap gap-[20px]">
                    <Box className="grow-[1]">
                      <DisabledTextField
                        label="Assunto"
                        value={results?.subject ?? ''}
                      />
                    </Box>
                  </Box>
                  <Box className="flex flex-wrap gap-[20px]">
                    <Box className="grow-[1]">
                      <DisabledTextField
                        label="Nº da Solicitação"
                        value={results?.id.toString() ?? '0'}
                      />
                    </Box>
                    <Box className="grow-[1]">
                      <DisabledTextField
                        label="Data e Hora da criação"
                        value={formatDate(results?.createdOn ?? new Date())}
                      />
                    </Box>
                    <Box className="grow-[1]">
                      <DisabledTextField
                        label="Data e Hora da atualização"
                        value={formatDate(results?.updatedOn ?? new Date())}
                      />
                    </Box>
                  </Box>
                  <Box className="flex flex-wrap gap-[20px]">
                    <Box className="grow-[1] min-[600px]:min-w-[455.33px]">
                      <DisabledTextField
                        label="Entedidade Solicitante"
                        value={results?.customFields?.[4].value ?? ''}
                      />
                    </Box>
                    <Box className="grow-[1] min-[600px]:min-w-[455.33px]">
                      <DisabledTextField
                        label="Usuário Solicitante"
                        value={results?.customFields?.[1].value ?? ''}
                      />
                    </Box>
                    <Box className="grow-[1] min-[600px]:min-w-[455.33px]">
                      <DisabledTextField
                        label="Telefone"
                        value={results?.telefoneSolicitante ?? ''}
                      />
                    </Box>
                  </Box>
                  <DisabledTextArea
                    label="Justificativa"
                    value={results?.description ?? ''}
                  />
                  {results?.attachments && results?.attachments.length > 0 ? (
                    <Box className="flex flex-col gap-[20px]">
                      <Box className="flex flex-col gap-[5px]">
                        <p className="text-[#424242] font-bold">Anexos</p>
                        <p className="text-[#424242] font-[500]">
                          Tipos de arquivos aceitos: pdf, png, jpg, tiff |
                          Tamanho máximo do arquivo: 2M
                        </p>
                      </Box>
                      <ul className="list-item gap-[10px] list-disc ml-[40px]">
                        {results?.attachments.map((file) => (
                          <li
                            key={file.id}
                            className="hover:text-[#004394] hover:cursor-pointer mb-[10px]"
                            onClick={() => getFile({id: file.id})}>
                            <Box className="flex gap-[10px] items-center">
                              {file.contentUrl} <BsDownload />
                            </Box>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ) : null}
                </Box>
                <Box className="flex flex-col gap-[20px] mt-[20px] p-[20px] border-[1px] border-[#ccc] rounded-[6px]">
                  <p className="font-bold text-[28px]">
                    Dados Específicos do Serviço
                  </p>
                  <Box className="flex flex-col gap-[20px]">
                    {dadosEspecificosDoServico?.map((data, index) => {
                      if (
                        customfields?.some(
                          (custom) =>
                            custom.fieldFormat === 'attachment' &&
                            custom.id === data.id,
                        )
                      )
                        return data.value ? (
                          <Box
                            key={data.id}
                            className="flex flex-col gap-[10px]">
                            <p className="text-[#6B7280]">{data.name ?? ''}</p>

                            <ul className="list-item gap-[10px] list-disc ml-[40px]">
                              <li
                                className="hover:text-[#004394] hover:cursor-pointer mb-[10px]"
                                onClick={() => {
                                  getFile({
                                    id: Number(data.value),
                                  })
                                }}>
                                <Box className="flex gap-[10px] items-center">
                                  Baixar Anexo <BsDownload />
                                </Box>
                              </li>
                            </ul>
                          </Box>
                        ) : null
                      else if (data.id === 134 || data.id === 152)
                        return (
                          <DisabledSelectField
                            key={index}
                            label={data.name ?? ''}
                            value={data.value ?? ''}
                          />
                        )
                      else
                        return (
                          <DisabledTextField
                            key={index}
                            label={data.name ?? ''}
                            value={data.value ?? ''}
                          />
                        )
                    }) ?? null}
                  </Box>
                </Box>
                <Box className="flex flex-col gap-[20px] mt-[20px] p-[20px] border-[1px] border-[#ccc] rounded-[6px]">
                  <p className="font-bold text-[28px]">
                    Dados de Acompanhamento
                  </p>
                  {dadosDeAcompanhamento?.map((data) => {
                    return (
                      <Box key={data.id} className="flex flex-col gap-[20px]">
                        <DisabledTextField
                          label={data.name ?? ''}
                          value={data.value ?? ''}
                        />
                      </Box>
                    )
                  }) ?? null}
                </Box>
                <Box className="flex flex-col gap-[10px] mt-[20px] p-[20px] border-[1px] border-[#ccc] rounded-[6px]">
                  <p className="font-bold text-[28px]">
                    Histórico de Atualizações
                  </p>
                  <Box className="flex flex-col gap-[10px] p-[20px]">
                    {results?.journals.map((data) => {
                      return (
                        <CardHistorico
                          key={data.id}
                          journal={data}
                          attachments={results.attachments}
                          customFields={results.customFields}
                        />
                      )
                    })}
                  </Box>
                </Box>
              </Box>
              <Button
                variant="contained"
                className="gap-[5px] max-w-[152px]"
                sx={{textTransform: 'none'}}
                onClick={() => setModalComentario(true)}>
                <FaRegCircleCheck size={20} />
                <span>Comentar</span>
              </Button>
            </>
          )}
        </Box>
      </Box>
      <ModalComentario
        id={id ?? ''}
        open={modalComentario}
        onClose={(value) => {
          value ? handleCloseWithRefetch() : setModalComentario(false)
        }}
      />
    </>
  )
}

export default DetalhesDaSolicitacao
