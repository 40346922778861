/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'

import {zodResolver} from '@hookform/resolvers/zod'
import {Box, CircularProgress, Typography} from '@mui/material'
import {TextFieldRHF} from 'components/atoms/form/TextFieldRHF'
import {IconArrowRight} from 'components/atoms/icons/IconArrowRight'
import {IconLock} from 'components/atoms/icons/IconLock'
import {IconPiaui} from 'components/atoms/icons/IconPiaui'
import {IconSecretaria} from 'components/atoms/icons/IconSecretaria'
import {IconUser} from 'components/atoms/icons/IconUser'
import {LogoGov} from 'components/atoms/icons/LogoGov'
import {useForm} from 'react-hook-form'
import {PiBooksLight} from 'react-icons/pi'
import {useAuth} from 'services/auth'

import {LoginSchema, loginSchema} from './loginSchema'
import * as S from './styles'

export const Login = () => {
  const {control, handleSubmit, reset} = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  })
  const {onSignIn, isLoading} = useAuth()

  const handleSignIn = ({email, password}: LoginSchema) => {
    onSignIn({
      email,
      password,
    })
  }

  return (
    <S.Wrapper>
      <S.ContentLeft>
        <S.Title>Portal de serviços</S.Title>

        <div className="ContentLeft__icons">
          <IconSecretaria />

          <div className="icons__second">
            <IconPiaui />
          </div>
        </div>
      </S.ContentLeft>
      <S.ContentRight>
        <S.Form>
          <TextFieldRHF
            label="Login"
            startIcon={<IconUser />}
            name="email"
            placeholder="Digite o email..."
            control={control}
          />
          <TextFieldRHF
            label="Senha"
            startIcon={<IconLock />}
            type="password"
            name="password"
            placeholder="Digite a senha..."
            control={control}
          />
          <Box className="flex gap-[20px] items-center justify-between">
            <Box className="flex flex-wrap gap-[32px] ">
              <a
                href="https://drive.google.com/file/d/15LnJ4F15WBjFEROR36W338WTtRtPTw2C/view?usp=sharing"
                target="_blank"
                className="flex gap-[10px] items-center p-[7px] rounded-md border-[#004394] text-[#004394]"
                rel="noreferrer">
                <PiBooksLight size={24} color="#004394" /> Guia de Acesso
              </a>
            </Box>
            <Box>
              <S.Button
                variant="contained"
                endIcon={<IconArrowRight />}
                fullWidth={false}
                onClick={() => handleSubmit(handleSignIn)()}
                disabled={isLoading}>
                {isLoading ? <CircularProgress size={16} /> : 'Acessar'}
              </S.Button>
            </Box>
          </Box>
        </S.Form>
      </S.ContentRight>
    </S.Wrapper>
  )
}
