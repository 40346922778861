import {z} from 'zod'

const ACCEPTED_FILE_TYPES = [
  'image/png',
  'application/pdf',
  'image/jpeg',
  'image/tiff',
]
const MAX_UPLOAD_SIZE = 1024 * 1024 * 2

export const modalComentarioSchema: z.ZodSchema<any> = z.object({
  comentario: z
    .string()
    .min(1, 'Este campo é obrigatório')
    .max(100, 'O comentário não pode ter mais que 100 caracteres'),
  file: z
    .any()
    .refine((file) => ACCEPTED_FILE_TYPES.includes(file.type), {
      message: 'O arquivo deve ser do tipo PNG, PDF, JPEG ou TIFF.',
    })
    .optional()
    .refine(
      (file) => file?.size <= MAX_UPLOAD_SIZE,
      `O tamanho máximo permitido do arquivo é 2MB.`,
    )
    .optional(),
})

export type ModalComentarioSchema = z.infer<typeof modalComentarioSchema>
