import {api} from 'api'
import {endpointsAuth} from 'infra'

import {ISignInCredentials, ISignResponse} from './authTypes'

async function signIn(data: ISignInCredentials) {
  const response = await api.post<ISignResponse>(endpointsAuth.signIn, data)
  return response.data
}

export const authApi = {
  signIn,
}
