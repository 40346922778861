import {useState, createContext, useCallback, useEffect} from 'react'

import {ModalChangeSchool} from 'components/atoms/ModalChangeSchool/ModalChangeSchool'
import {ILotacaoUser, ISignResponse, useAuthSignIn} from 'domains/Auth'
import {useGetStatus} from 'domains/ServiceDomain/useCases/useGetStatus'
import {toast} from 'react-toastify'

import {authStorage} from '../authStorage'
import {IAuthService} from '../authTypes'

export const AuthContext = createContext<IAuthService>({} as IAuthService)

export const AuthProvider: React.FC<{children: any}> = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!authStorage.getUser(),
  )
  const [userData, setUserData] = useState(() => {
    const user = authStorage.getUser()
    return user ?? ({} as ISignResponse)
  })
  const [openModal, setOpenModal] = useState(false)
  const [lotacaoSelecionada, setLotacaoSelecionada] = useState<ILotacaoUser>(
    () => {
      const lotacao = authStorage.getLotacao()
      return lotacao ?? ({} as ILotacaoUser)
    },
  )

  const {signIn, isLoading, isSuccess} = useAuthSignIn({
    onSuccess: (data) => {
      setUserData(data)
      setIsAuthenticated(true)
      authStorage.setLotacao(data.lotacoes?.[0] ?? {})
      authStorage.setUser(data)
    },
  })

  const {data: status} = useGetStatus()

  const onSignIn = useCallback(
    ({password, email}: {password: string; email: string}) => {
      signIn({email, password})
    },
    [signIn],
  )

  const handleOnClose = () => {
    setOpenModal(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleChangeLotacao = useCallback(
    (lotacaoId: number) => {
      const {lotacoes} = userData
      const findLotacao = lotacoes.find((lotacao) => lotacao.id === lotacaoId)

      if (findLotacao) {
        authStorage.setLotacao(findLotacao)
        setLotacaoSelecionada(findLotacao)
        toast.success('Lotação alterada com sucesso')
        handleOnClose()
      } else {
        toast.error('Erro ao mudar lotaçǎo')
      }
    },
    [userData],
  )

  const onSignOut = useCallback(() => {
    authStorage.removeUser()
    authStorage.removeLotacao()
    setIsAuthenticated(false)
    setUserData({} as ISignResponse)
  }, [])

  useEffect(() => {
    const hasLotacao = !!userData?.lotacoes?.length
    if (isSuccess && hasLotacao) setLotacaoSelecionada(userData.lotacoes[0])
  }, [isSuccess, userData])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        onSignIn,
        onSignOut,
        status,
        userData,
        isLoading,
        handleChangeLotacao,
        lotacaoSelecionada,
        handleOpenModal,
      }}>
      {children}

      {openModal && (
        <ModalChangeSchool open={openModal} handleClose={handleOnClose} />
      )}
    </AuthContext.Provider>
  )
}
