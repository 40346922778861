import {
  cpfCnpjInputTransform,
  intInputTransform,
  doubleInputTransform,
  phoneInputTransform,
  cepInputTransform,
  rgInputTransform,
} from './transforms'

export const masks = {
  cpf: cpfCnpjInputTransform,
  number: intInputTransform,
  float: doubleInputTransform,
  phone: phoneInputTransform,
  cep: cepInputTransform,
  rg: rgInputTransform,
}
