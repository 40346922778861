import React, {useRef} from 'react'

import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import {Box, Button, FormControl, Typography} from '@mui/material'
import {red} from '@mui/material/colors'
import {Controller, UseControllerProps, useFormContext} from 'react-hook-form'

interface IUpload {
  nameButton?: string // Changed String to string
  name?: string
  control: any
  setValue?: unknown
  resetfield?: () => void
}

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  IUpload &
  UseControllerProps

export const FileInput: React.FC<Props> = ({
  nameButton,
  name,
  control,
  rules,
  resetfield,
  ...otherInput
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleRemoveAnexo = () => {
    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  return (
    <>
      <Box className="gap-4">
        <FormControl fullWidth size={'small'}>
          <Controller
            rules={rules}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <div className={'relative overflow-hidden z-[99] grow-[1]'}>
                <Box className="flex flex-wrap gap-[10px]">
                  <div className="grow-[1]">
                    <input
                      onChange={(e) => {
                        onChange(e.target.files?.[0])
                      }}
                      accept="*"
                      type="file"
                      ref={fileInputRef}
                      multiple={false}
                      className={
                        'top-0 left-0 absolute items-center opacity-0 text-[0px] cursor-pointer display-none w-full h-[58px]'
                      }
                    />
                    <div
                      className={
                        error
                          ? 'flex items-center p-[10px] outline-[#E10E0E] flex-wrap gap-[10px] min-h-[58px] rounded-[6px] border-[1px] border-[#E10E0E] bg-[#fff]'
                          : 'flex items-center p-[10px] outline-[#E1E8FF] flex-wrap gap-[10px] min-h-[58px] rounded-[6px] border-[1px] border-[#DFE4EA] bg-[#fff]'
                      }>
                      <label
                        className={
                          'min-h-[34px] min-w-[190px] px-[24px] flex flex-wrap justify-center rounded-[6px] items-center bg-[#E3E3E3]'
                        }>
                        <div className="text-[#000000] ">Anexar arquivo</div>
                      </label>
                      <span
                        className={
                          'overflow-hidden text-ellipsis text-[black]'
                        }>
                        {value?.name ? (
                          <p className="text-black">{value?.name}</p>
                        ) : (
                          <p className="text-[#9CA3AF]">
                            Nenhum arquivo selecionado
                          </p>
                        )}
                      </span>
                    </div>
                    {error ? (
                      <ul className="list-disc">
                        {error.message && (
                          <li className="text-[12px] text-[#E10E0E]">
                            {error.message}
                          </li>
                        )}
                      </ul>
                    ) : null}
                  </div>
                  {value ? (
                    <Button
                      onClick={(e) => {
                        onChange('')
                        if (fileInputRef.current)
                          fileInputRef.current.value = ''
                      }}
                      color="error">
                      Remover Anexo
                    </Button>
                  ) : null}
                </Box>
              </div>
            )}
            control={control}
            name={name}
          />
        </FormControl>
        {/* <Button
          className="w-[180px]"
          variant="contained"
          size="small"
          startIcon={<TaskOutlinedIcon />}
          onClick={handleButtonClick}>
          {nameButton}
        </Button> */}
      </Box>
    </>
  )
}

export default FileInput
