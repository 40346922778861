import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'
export function useGetEntidadeSolicitante({
  idGre,
  isGRE,
  enabled,
}: {
  idGre: string
  isGRE: string
  enabled?: boolean
}) {
  return useQuery({
    queryKey: [QueryKeys.GetEntidadeSolicitante, {idGre, isGRE}],
    queryFn: () => {
      return serviceDomainService.getEntidadesFromGre(idGre, isGRE)
    },
    enabled,
  })
}
