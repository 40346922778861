import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import {Button} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import CustomButton from '../Button/ButtonComponent'

interface ModalProps {
  open: boolean
  handleClose: () => void
  title: string
  content: string
  bold: string
  style?: React.CSSProperties
  handleSubmitModal: () => void
  handleCancelModal: () => void
}

const defaultStyle: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  border: '1px solid white',
  boxShadow: '24px',
  borderRadius: '10px',
  padding: 4,
}

function ModalAproved({
  open,
  handleClose,
  title,
  content,
  bold,
  style,
  handleCancelModal,
  handleSubmitModal,
}: ModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{...defaultStyle, ...style}}>
        <div onClick={handleClose} className="cursor-pointer">
          <CloseIcon />
        </div>
        <Typography
          id="modal-modal-title"
          sx={{mt: 4, fontSize: 24, fontWeight: 600}}>
          {title}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{mt: 4, mb: 4, fontSize: 18, fontWeight: 400}}>
          {content} <span className="font-bold">{bold}</span>
        </Typography>
        <Box className="flex justify-end items-end gap-4 my-4">
          <Button
            startIcon={<AddIcon />}
            onClick={handleSubmitModal}
            variant="contained">
            Confirmar
          </Button>
          <Button
            startIcon={<ClearIcon />}
            onClick={handleCancelModal}
            color="error"
            variant="contained">
            Fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalAproved
