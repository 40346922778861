import {MutationOptions} from 'infra'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

import {serviceDomainService} from '../serviceDomainService'
import {IAprovarSolicitacao} from '../serviceDomainTypes'

export function UseAprovarSolicitacao(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, IAprovarSolicitacao>({
    mutationFn: async (dataPost: IAprovarSolicitacao) => {
      return await serviceDomainService.AprovarSolicitacao(dataPost)
    },
    retry: false,
    onError: (error: any) => {
      toast.error(error.message.response.data ?? 'Falha ao aprovar solicitação')
      if (options?.onError) {
        console.log(options)
        options.onError(error.message)
      }
    },
    onSuccess: (data: any) => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoadingSolicitacao: mutation.isLoading,
    AprovarSolicitacao: (variables: IAprovarSolicitacao) =>
      mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
