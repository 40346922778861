import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'
import {IGetMinhasSolicitacoesRequest} from '../serviceDomainTypes'

export const useGetMinhasSolicitacoes = ({
  perfilId,
  id,
  statusId,
  gre,
  servico,
  subject,
  dataAbertura,
  dataAtualizacao,
  categoria,
  escola,
}: IGetMinhasSolicitacoesRequest) => {
  return useQuery({
    queryKey: [
      QueryKeys.GetMinhasSolicitacoes,
      {
        perfilId,
        id,
        statusId,
        gre,
        escola,
        servico,
        dataAbertura,
        dataAtualizacao,
        categoria,
        subject,
      },
    ],
    queryFn: () => {
      return serviceDomainService.getMinhasSolicitacoes({
        perfilId,
        id,
        statusId,
        gre,
        servico,
        subject,
        dataAbertura,
        dataAtualizacao,
        categoria,
        escola,
      })
    },
  })
}
