import {Dispatch, SetStateAction, useState} from 'react'

import {LoadingButton} from '@mui/lab'
import {Box, IconButton} from '@mui/material'
import {
  IAttachments,
  ICustomFields,
  IGetFileRequest,
  IGetFileResponse,
  IJournals,
} from 'domains/ServiceDomain'
import {useGetFile} from 'domains/ServiceDomain/useCases/useGetFile'
import {
  formatDate,
  showStatus,
} from 'pages/home/minhasSolicitacoes/minhasSolicitacoes'
import {BsDownload} from 'react-icons/bs'
import {IoAlertCircleOutline} from 'react-icons/io5'

interface ICardHistorico {
  journal: IJournals
  customFields: Array<ICustomFields>
  attachments: Array<IAttachments>
}

const CardHistorico = ({
  journal,
  customFields,
  attachments,
}: ICardHistorico) => {
  const showFile = (attachment: IAttachments) => {
    getFile({
      id: attachment.id,
    })
  }
  const {getFile} = useGetFile({
    onSuccess: (data: IGetFileResponse) => {
      const linkSource = `data:${data.contentType};base64,${data.content}`
      const downloadLink = document.createElement('a')
      const fileName = data.fileName

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  })
  return journal?.detailsFormatados?.length > 0 || journal.notes ? (
    <Box className="shadow-md p-[24px] border-[1px] border-[#DFE4EA] rounded-[6px]">
      <Box className="flex gap-[20px]">
        <IoAlertCircleOutline
          size={20}
          className="bg-[#E1E8FF] min-h-[34px] min-w-[34px] p-[8px] rounded-[6px]"
          color="#2D68F8"
        />
        <Box className="flex flex-col gap-[20px] justify-between">
          <Box className="flex gap-[10px]">
            <p className="font-bold">
              {formatDate(journal.createdOn)} - {journal.user.name}
            </p>
          </Box>
          <Box className="flex flex-col gap-[20px] justify-between">
            {journal?.detailsFormatados?.length > 0 ? (
              <ul className="list-item list-disc ml-[40px]">
                {journal?.detailsFormatados.map((detail, index) => {
                  if (detail?.property === 'attr') {
                    return (
                      <li
                        key={index}
                        className="flex flex-wrap items-center gap-[10px]">
                        <b>Status</b> alterado de{' '}
                        <b>{showStatus(Number(detail.oldValue))}</b> para{' '}
                        <b>{showStatus(Number(detail.newValue))}</b>
                      </li>
                    )
                  } else if (detail?.property === 'cf') {
                    if (detail?.oldValue) {
                      return (
                        <li key={index}>
                          <b>
                            {
                              customFields?.find(
                                (field) => field?.id === Number(detail?.name),
                              )?.name
                            }
                          </b>{' '}
                          <span>
                            alterado de <b>{detail?.oldValue}</b> para{' '}
                            <b>{detail?.newValue}</b>
                          </span>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <b>
                            {
                              customFields?.find(
                                (field) => field?.id === Number(detail?.name),
                              )?.name
                            }
                          </b>{' '}
                          ajustado para <b>{detail?.newValue}</b>
                        </li>
                      )
                    }
                  } else {
                    return (
                      <li
                        key={index}
                        className="flex flex-wrap gap-[5px] items-center">
                        <span>
                          <b>Anexo: </b>
                        </span>
                        <a
                          className="text-[#004394] cursor-pointer hover:underline flex gap-[10px] items-center"
                          onClick={() =>
                            showFile(
                              attachments.find(
                                (field) => field.id === Number(detail.name),
                              ) ?? ({} as IAttachments),
                            )
                          }>
                          {detail.newValue}
                          <BsDownload />
                        </a>
                      </li>
                    )
                  }
                })}
              </ul>
            ) : null}
            {journal.notes ? (
              <div className="flex items-center gap-[5px]">
                <b>Comentário:</b>
                {journal.notes}
              </div>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null
}

export default CardHistorico
