import {ILotacaoUser, ISignResponse} from 'domains/Auth'
import {LocalStorage} from 'infra'

function getUser(): ISignResponse | null {
  const accessTokenLocalStorage = window.localStorage.getItem(
    LocalStorage.USER_LOGGED,
  )
  if (accessTokenLocalStorage) {
    const parseAccessToken = JSON.parse(accessTokenLocalStorage)
    return parseAccessToken
  }
  return null
}

async function setUser(value: ISignResponse) {
  window.localStorage.setItem(LocalStorage.USER_LOGGED, JSON.stringify(value))
}

async function removeUser() {
  window.localStorage.removeItem(LocalStorage.USER_LOGGED)
}

// LOTACAO
function getLotacao(): ILotacaoUser | null {
  const lotacaoStorage = window.localStorage.getItem(
    LocalStorage.LOTACAO_SELECIONADA,
  )
  if (lotacaoStorage) {
    const parseAccessToken = JSON.parse(lotacaoStorage)
    return parseAccessToken
  }
  return null
}

async function setLotacao(value: ILotacaoUser) {
  window.localStorage.setItem(
    LocalStorage.LOTACAO_SELECIONADA,
    JSON.stringify(value),
  )
}

async function removeLotacao() {
  window.localStorage.removeItem(LocalStorage.LOTACAO_SELECIONADA)
}

export const authStorage = {
  getUser,
  setUser,
  removeUser,
  getLotacao,
  setLotacao,
  removeLotacao,
}
