export const endpointsAuth = {
  signIn: '/auth',
}

export const endpointsService = {
  getAllServices: '/servico',
  getMinhasSolicitacoes: '/servico/solicitacao',
  getStatus: '/servico/status',
  getCustomFields: '/servico/campos?servico_id=:id',
  AbrirSolicitacao: 'servico/solicitacao',
  AprovarSolicitacao: 'servico/solicitacao/:id',
  getSolicitacao: 'servico/solicitacao/:id',
  putModalComentario: 'servico/solicitacao',
  getFile: 'servico/imagem',
  postFavoritar: 'servico/favoritos',
  getFileDownload: 'servico/imagem?id=:id',
  getEntidadeSolicitante: '/servico/gre?codigoGre=:id&isGerente=:isGRE',
  getPendenciasListaDeAprovacao: '/servico/solicitacao/pendentes',
  getCategorias: '/servico/categoria',
  getServiceCategory: '/servico/projeto/categoria',
}
