import {AxiosError} from 'axios'
import {MutationOptions} from 'infra'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

import {serviceDomainService} from '../serviceDomainService'
import {IGetFileRequest, IGetFileResponse} from '../serviceDomainTypes'

export function useGetFile(options?: MutationOptions<any>) {
  const mutation = useMutation<IGetFileResponse, AxiosError, IGetFileRequest>({
    mutationFn: async (dataGet: IGetFileRequest) => {
      return await serviceDomainService.getFile(dataGet)
    },
    retry: false,
    onError: (error: AxiosError) => {
      console.log(error)
      toast.error('Falha ao fazer download do arquivo')
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data: IGetFileResponse) => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoadingFile: mutation.isLoading,
    getFile: (data: IGetFileRequest) => mutation.mutate(data),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
