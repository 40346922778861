import {ChangeEvent, useState} from 'react'

import {Box} from '@mui/material'
import TextFieldComponent from 'components/TextFieldComponent'
import {GoSearch} from 'react-icons/go'

import Navbar from '../navbar/Navbar'

interface IHeader {
  handleSearchChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

const Header = ({handleSearchChange}: IHeader) => {
  const [data, setData] = useState<string>('')

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {value} = e.target
    setData(value)
    handleSearchChange(e)
  }

  return (
    <>
      <Navbar />
      <Box className="h-[300px] w-full">
        <div className="absolute bg-[url('/public/assets/background-image.jpg')] bg-cover bg-[#004394] bg-blend-multiply h-[300px] w-full" />
        <div className="relative flex items-center flex-col w-full gap-[20px] h-[300px] p-[10px] justify-center">
          <p className="font-[700] text-white text-[24px]">
            PORTAL DE SERVIÇOS
          </p>
          <Box className="max-w-[642px] w-full">
            <TextFieldComponent
              error={''}
              handleChange={handleChange}
              value={data}
              label=""
              type="text"
              placeholder="Buscar por tipo ou nome do serviço">
              <GoSearch color="black" />
            </TextFieldComponent>
          </Box>
        </div>
      </Box>
    </>
  )
}

export default Header
