// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper_progressBar__OABc0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
}

.stepper_step__-xGW5 {
  width: 60px;
  height: 60px;
  max-width: 60px;
  max-height: 60px;
  background: #d1d5db;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.stepper_step__-xGW5.stepper_active__\\+ocJ3 {
  border: 2px solid #2d68f8;
}

.stepper_step__-xGW5.stepper_checked__P0Nh6 {
  background-color: #2d68f8;
  border: 2px solid #2d68f8;
  color: #fff;
}

.stepper_separator__Unhf\\+ {
  width: 100%;
  position: relative;
  height: 2px;
  margin-top: -60px;
  background-color: #d1d5db;
}

.stepper_separator__Unhf\\+.stepper_checked__P0Nh6 {
  background-color: #2d68f8;
}
`, "",{"version":3,"sources":["webpack://./src/styles/stepper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".progressBar {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  background-color: #fff;\n  overflow: hidden;\n  border-radius: 5px;\n}\n\n.step {\n  width: 60px;\n  height: 60px;\n  max-width: 60px;\n  max-height: 60px;\n  background: #d1d5db;\n  border-radius: 50%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.step.active {\n  border: 2px solid #2d68f8;\n}\n\n.step.checked {\n  background-color: #2d68f8;\n  border: 2px solid #2d68f8;\n  color: #fff;\n}\n\n.separator {\n  width: 100%;\n  position: relative;\n  height: 2px;\n  margin-top: -60px;\n  background-color: #d1d5db;\n}\n\n.separator.checked {\n  background-color: #2d68f8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `stepper_progressBar__OABc0`,
	"step": `stepper_step__-xGW5`,
	"active": `stepper_active__+ocJ3`,
	"checked": `stepper_checked__P0Nh6`,
	"separator": `stepper_separator__Unhf+`
};
export default ___CSS_LOADER_EXPORT___;
