import {Box} from '@mui/material'

interface IDisabledTextArea {
  label: string
  value: string | undefined
}

const DisabledTextArea = ({label, value}: IDisabledTextArea) => {
  return (
    <Box className="flex flex-col gap-[10px] w-full">
      <p className="text-[#6B7280]">{label}</p>
      <textarea
        className={
          'bg-[#F3F4F6] text-[#9CA3AF] px-[16px] py-[12px] rounded-[6px] min-h-[180px] h-full'
        }
        rows={4}
        disabled
        value={value || ''}
      />
      <Box className="flex justify-end">
        <p className="text-[13px]">{value?.length}/1000</p>
      </Box>
    </Box>
  )
}

export default DisabledTextArea
