import {ThemeProvider} from '@emotion/react'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material'
import './styles/index.css'
import {queryClient} from 'config'
import {QueryClientProvider} from 'react-query'
import {BrowserRouter} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {AuthProvider} from 'services/auth'

import {Routes} from './routes'

import 'react-toastify/dist/ReactToastify.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#004394', // Altere as cores conforme necessário
    },
    error: {
      main: '#E10E0E', // Altere as cores conforme necessário
    },
    warning: {
      main: '#FCB900',
    },
    action: {
      disabledBackground: '#E5E7EB',
      disabled: '#8899A8',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 46,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #ADBCF2',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E10E0E',
          },
          background: 'white',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontFamily: 'Inter',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          minHeight: 40,
          fontFamily: 'Inter',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: 16,
          color: '#23292e',
          marginBottom: 10,
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter', // Fonte padrão
      'Arial',
      'sans-serif',
    ].join(','),
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <MuiThemeProvider theme={{}}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes />
              <ToastContainer />
            </ThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
