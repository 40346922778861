import {useEffect, useState} from 'react'

import {Box} from '@mui/material'
import {IGetStatus} from 'domains/ServiceDomain'
import {AiOutlineClose} from 'react-icons/ai'
import {IoCheckmarkOutline} from 'react-icons/io5'
import {useAuth} from 'services/auth'

import styles from '../../../styles/stepper.module.css' // Importe o arquivo de estilos como um objeto

const Stepper = ({
  active,
  rejeitadoId,
}: {
  active: number
  rejeitadoId: number | null
}) => {
  const {status} = useAuth()

  const [steps, setSteps] = useState<IGetStatus[]>([])

  useEffect(() => {
    if (status) {
      setSteps(status.filter((value) => value.id !== 28 && value.id !== 29))
    }
  }, [status, rejeitadoId])

  const renderedSteps = steps.map((stepNumber, index) => {
    const classNames = `${styles.step} ${active > stepNumber.id ? styles.checked : ''}`

    return (
      <Box
        className={
          index === 0
            ? 'flex items-center w-full max-w-[250px] ml-[30px] justify-start'
            : index !== steps.length - 1
              ? 'flex items-center w-full max-w-[250px] justify-start'
              : 'flex items-center min-w-[90px]'
        }
        key={stepNumber.id}>
        <Box className="relative flex flex-col max-h-[140px] max-w-[70px] items-center h-full w-full">
          <div className={classNames}>
            {
              active === stepNumber.id ? (
                !rejeitadoId ? (
                  <span className="relative w-full h-full flex items-center justify-center rounded-[50px] bg-[#2D68F8]">
                    <Box className="p-[5px] border-[1px] border-[#fff] rounded-[50px]">
                      <IoCheckmarkOutline color="white" />
                    </Box>
                  </span>
                ) : (
                  <span className="w-full h-full flex items-center justify-center rounded-[50px] bg-[#E10E0E]">
                    <Box className="p-[5px] border-[1px] border-[#fff] rounded-[50px]">
                      <AiOutlineClose color="white" />
                    </Box>
                  </span>
                )
              ) : active > stepNumber.id ? (
                <Box className="p-[5px] border-[1px] border-[#fff] rounded-[50px]">
                  <IoCheckmarkOutline color="white" />
                </Box>
              ) : (
                <Box className="p-[5px] border-[1px] border-[#fff] rounded-[50px]">
                  <IoCheckmarkOutline color="white" />
                </Box>
              )
              // stepNumber.id === 28 || stepNumber.id === 29 ? (
              //   <span className="w-full h-full flex items-center justify-center rounded-[50px] bg-[#2D68F8]">
              //     <Box className="p-[5px] border-[1px] border-[#fff] rounded-[50px]">
              //       <IoCheckmarkOutline color="white" />
              //     </Box>
              //   </span>
              // ) : (
              //     <Box className="p-[5px] border-[1px] border-[#fff] rounded-[50px]">
              //     <IoCheckmarkOutline color="white" />
              //   </Box>
              // )
            }
          </div>
          <Box className="relative flex mt-[20px] justify-center items-start max-w-[100px] min-h-[40px] h-full bottom-0 min-w-[110px]">
            <p className="font-bold text-center text-[14px]">
              {stepNumber.name}
            </p>
          </Box>
        </Box>
        {index === steps.length - 1 ? (
          <p className="w-0"></p>
        ) : (
          <div
            className={`relative ${styles.separator} ${active > stepNumber.id ? styles.checked : ''}`}
          />
        )}
      </Box>
    )
  })

  return <div className={styles.progressBar}>{renderedSteps}</div>
}

export default Stepper
