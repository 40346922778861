import {Box} from '@mui/material'
import {Controller, UseControllerProps, FieldValues} from 'react-hook-form'

import {TextFieldCom, TextFieldProps} from '../textfield/textfield'

export function DisabledRHFTextField<FormType extends FieldValues>({
  name,
  rules,
  control,
  label,
  valueDefault,
  ...textFieldParams
}: {
  name: string
  label: string
  valueDefault: any
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={valueDefault}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <>
          <Box className="flex flex-col gap-[10px] w-full">
            <p className="text-[#6B7280]">{label}</p>
            <input
              className={
                'bg-[#F3F4F6] text-[#9CA3AF] px-[16px] py-[12px] rounded-[6px]'
              }
              disabled
              value={value || ''}
            />
          </Box>
        </>
      )}
    />
  )
}
