/* eslint-disable no-useless-catch */
import {BASE_URL, api} from 'api'
import {AxiosResponse} from 'axios'
import {endpointsService} from 'infra'

import {
  IGetDetalhesDaSolicitacaoRequest,
  IGetDetalhesDaSolicitacaoResponse,
  IGetMinhasSolicitacoesRequest,
  IGetMinhasSolicitacoesResponse,
  IGetServicesResponse,
  IGetStatus,
  ICustomField,
  IAprovarSolicitacao,
  ISolicitacao,
  IssueDataSolicitacao,
  IPutModalComentarioRequest,
  IGetFileRequest,
  IGetFileResponse,
  IPostFavoritoParams,
  FileData,
  EntidadeSolicitante,
  Categoria,
} from './serviceDomainTypes'

async function getAllServices({
  email,
  favorito,
  identificador,
  nome,
}: {
  email: string
  favorito?: boolean | string
  identificador?: string
  nome?: string
}): Promise<AxiosResponse<IGetServicesResponse[]>> {
  return api.get(
    `${endpointsService.getAllServices}/?email=${email}&favorito=${favorito ?? ''}&identificador=${identificador ?? ''}&nome=${nome ?? ''}`,
    {
      headers: {
        Authorization: '1Zm0juAbCy1DgK3cIVozbYEiJj9MCeyg',
      },
    },
  )
}

async function getMinhasSolicitacoes({
  perfilId,
  id,
  statusId,
  gre,
  servico,
  subject,
  dataAbertura,
  dataAtualizacao,
  categoria,
  escola,
}: IGetMinhasSolicitacoesRequest): Promise<
  AxiosResponse<IGetMinhasSolicitacoesResponse[]>
> {
  return api.get(
    endpointsService.getMinhasSolicitacoes +
      `/?perfilId=${perfilId}&gre=${gre}&escola=${escola}&issueId=${id}&statusId=${statusId}&descricao=${subject}&trackerId=${servico}&dataCriacaoInicio=${dataAbertura}&dataCriacaoFim=${dataAtualizacao}&categoria=${categoria}`,
  )
}

async function getStatus(): Promise<AxiosResponse<IGetStatus[]>> {
  return api.get(endpointsService.getStatus)
}

async function getDetalhesDaSolicitacao({
  solicitacaoId,
}: IGetDetalhesDaSolicitacaoRequest): Promise<
  AxiosResponse<IGetDetalhesDaSolicitacaoResponse>
> {
  return api.get(endpointsService.getMinhasSolicitacoes + `/${solicitacaoId}`)
}

async function getCustomFields(
  idServico: string,
): Promise<AxiosResponse<ICustomField[]>> {
  return api.get(endpointsService.getCustomFields.replace(':id', idServico))
}

async function getSolicitacao(
  idSolicitacao: string,
): Promise<AxiosResponse<ISolicitacao>> {
  return api.get(endpointsService.getSolicitacao.replace(':id', idSolicitacao))
}

async function AbrirSolicitacao(
  dataPost: IssueDataSolicitacao,
): Promise<AxiosResponse<any>> {
  return api.post(endpointsService.AbrirSolicitacao, dataPost)
}

async function postFavoritar(
  params: IPostFavoritoParams,
): Promise<AxiosResponse<any>> {
  return api.post(endpointsService.postFavoritar, params)
}

async function deleteFavoritar(params: IPostFavoritoParams): Promise<any> {
  return api.delete(endpointsService.postFavoritar, {
    params,
  })
}

async function AprovarSolicitacao(
  data: IAprovarSolicitacao,
): Promise<AxiosResponse<any>> {
  return api.put(
    endpointsService.AprovarSolicitacao.replace(':id', data.idSolicitacao),
    data,
  )
}

async function putModalComentario(
  data: IPutModalComentarioRequest,
): Promise<AxiosResponse<any>> {
  return api.put(endpointsService.putModalComentario + `/${data.id}`, {
    nota: data.comentario,
    uploads: data.file
      ? [
          {
            filename: data.file.name.replace(' ', '_'),
            content_type: data.file.type,
            content_base64: data.base64,
          },
        ]
      : [],
  })
}

async function getFile(
  data: IGetFileRequest,
): Promise<AxiosResponse<IGetFileResponse>> {
  return api.get(endpointsService.getFile + `/?id=${data.id}`)
}

async function getFileDownload(
  idFile: string,
): Promise<AxiosResponse<FileData>> {
  return api.get(endpointsService.getFileDownload.replace(':id', idFile))
}

async function getEntidadeForGre(
  idGre: string,
  isGRE: string,
): Promise<AxiosResponse<EntidadeSolicitante>> {
  return api.get(
    endpointsService.getEntidadeSolicitante
      .replace(':id', idGre)
      .replace(':isGRE', isGRE),
  )
}

async function getPendenciasListaDeAprovacao(
  idGre: string,
): Promise<AxiosResponse<number>> {
  return api.get(
    endpointsService.getPendenciasListaDeAprovacao + `/?gre=${idGre}`,
  )
}

async function getCategorias(): Promise<AxiosResponse<Array<string>>> {
  return api.get(endpointsService.getCategorias)
}

async function getServiceCategory(): Promise<AxiosResponse<Array<Categoria>>> {
  return api.get(endpointsService.getServiceCategory)
}

export const serviceDomainApi = {
  getAllServices,
  getMinhasSolicitacoes,
  getStatus,
  getDetalhesDaSolicitacao,
  AprovarSolicitacao,
  AbrirSolicitacao,
  getSolicitacao,
  getCustomFields,
  putModalComentario,
  getFile,
  postFavoritar,
  getFileDownload,
  deleteFavoritar,
  getEntidadeForGre,
  getPendenciasListaDeAprovacao,
  getCategorias,
  getServiceCategory,
}
