import AbrirSolicitacoes from 'pages/home/abrirSolicitacoes/abrirSolicitacoes'
import ListaDeAprovacoes from 'pages/home/listaDeAprovacoes/listaDeAprovacoes'
import {AprovarSolicitacoes} from 'pages/home/minhasSolicitacoes/aprovarSolicitacoes/aprovarSolicitacoes'
import DetalhesDaSolicitacao from 'pages/home/minhasSolicitacoes/detalhesDaSolicitacao/detalhesDaSolicitacao'
import MinhasSolicitacoes from 'pages/home/minhasSolicitacoes/minhasSolicitacoes'

import Home from '../pages/home/home'

export const privateRoutes = [
  {path: 'carta-de-servicos', component: Home, isPrivate: true, subRoutes: []},
  {
    path: '/carta-de-servicos/abrir-solicitacao/:id',
    component: AbrirSolicitacoes,
    isPrivate: true,
    subRoutes: [],
  },
  {
    path: '/carta-de-servicos/minhas-solicitacoes',
    component: MinhasSolicitacoes,
    isPrivate: true,
    subRoutes: [],
  },
  {
    path: '/carta-de-servicos/lista-de-aprovacoes',
    component: ListaDeAprovacoes,
    isPrivate: true,
    subRoutes: [],
  },
  {
    path: '/carta-de-servicos/minhas-solicitacoes/aprovacao-da-solicitacao/:id',
    component: AprovarSolicitacoes,
    isPrivate: true,
    subRoutes: [],
  },
  {
    path: '/carta-de-servicos/minhas-solicitacoes/detalhes-da-solicitacao/:id',
    component: DetalhesDaSolicitacao,
    isPrivate: true,
    subRoutes: [],
  },
]
