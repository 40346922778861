import {z} from 'zod'

export const issueMinhasSolicitacaoSchema: z.ZodSchema<any> = z
  .object({
    id: z.string().optional(),
    entidadeSolicitante: z.string().optional(),
    servico: z.string().optional(),
    status: z.string().optional(),
    categoria: z.string().optional(),
    dataAbertura: z.string().optional(),
    dataAtualizacao: z.string().optional(),
    subject: z.string().optional(),
  })
  .partial()
  .superRefine((data, ctx) => {
    if (data.dataAbertura && !data.dataAtualizacao) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['dataAtualizacao'],
        message:
          'Campo obrigatório, pois a data inicial de abertura da solicitação foi preenchida',
      })
    }
    if (!data.dataAbertura && data.dataAtualizacao) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['dataAbertura'],
        message:
          'Campo obrigatório, pois a data final da abertura da solicitação foi preenchida',
      })
    }
    if (
      data.dataAbertura &&
      data.dataAtualizacao &&
      new Date(data.dataAbertura) > new Date(data.dataAtualizacao)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['dataAbertura'],
        message: 'Data inicial está maior que a final, por favor corrija!',
      })
    }
  })

export type minhasSolicitacoesSchema = z.infer<
  typeof issueMinhasSolicitacaoSchema
>
