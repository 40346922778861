import {Box, Button as ButtonMui, Stack, Typography} from '@mui/material'
import Image from 'assets/images/login-container.png'
import styled from 'styled-components'

export const ContentLeft = styled.div`
  background-image: url(${Image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 100px;

  .ContentLeft__icons {
    display: flex;
    align-items: center;

    .icons__second {
      margin-left: 20px;
      display: relative;

      display: flex;
      align-items: center;

      &::before {
        content: '';
        background: #fff;
        height: 32px;
        width: 1px;
        display: block;
        margin-right: 20px;
      }
    }
  }
`

export const Title = styled(Typography)`
  && {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
`

export const ContentRight = styled(Box)`
  && {
    max-width: 470px;
    align-self: center;
    justify-self: center;
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
  }
`

export const Form = styled(Stack)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Button = styled(ButtonMui)`
  width: max-content;
  margin-bottom: 0px !important;
`
export const WrapperAccessGov = styled(Stack)`
  margin: 20px 0px;
`

export const ButtonGovPi = styled(ButtonMui)`
  && {
    margin-top: 16px;

    background-color: #fcb900;
    &:hover {
      background-color: #fcb900;
      filter: brightness(0.9);
    }
  }
`

export const Wrapper = styled(Box)`
  && {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100vw;
    height: 100vh;

    @media (max-width: 1024px) {
      display: flex;

      ${ContentLeft} {
        display: none;
      }
    }
  }
`
