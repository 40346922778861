export enum LocalStorage {
  USER_LOGGED = '@GOV:USER',
  LOTACAO_SELECIONADA = '@GOV:LOTACAO',
}

export enum QueryKeys {
  GetAllServices = 'GetAllServices',
  GetMinhasSolicitacoes = 'GetMinhasSolicitacoes',
  GetStatus = 'GetStatus',
  GetDetalhesDaSolicitacao = 'GetDetalhesDaSolicitacao',
  GetCustomFields = 'GetCustomFields',
  GetFile = 'GetFile',
  GetFileDownload = 'GetFileDownload',
  GetEntidadeSolicitante = 'GetEntidadeSolicitante',
  GetPendencias = 'GetPendenciasListaDeAprovacao',
  GetCategorias = 'GetCategorias',
  getServiceCategory = 'GetServiceCategory',
}
