import {Box} from '@mui/material'

interface IDisabledTextField {
  label: string
  value: string | undefined
}

const DisabledTextField = ({label, value}: IDisabledTextField) => {
  return (
    <Box className="flex flex-col gap-[10px] w-full">
      <p className="text-[#6B7280]">{label}</p>
      <input
        className={
          'bg-[#F3F4F6] text-[#9CA3AF] px-[16px] py-[12px] rounded-[6px]'
        }
        disabled
        value={value || ''}
      />
    </Box>
  )
}

export default DisabledTextField
