import React from 'react'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Controller, UseControllerProps} from 'react-hook-form'
import {IoChevronDownOutline} from 'react-icons/io5'

import {
  FormInputProps,
  TDropDownArray,
  TDropDownArraySoli,
} from './formInputDropDownProps'
export const useStyles = makeStyles({
  label: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 16,
    color: '#23292e',
    marginBottom: 10,
  },
})
export type DropDownFieldProps = FormInputProps &
  SelectProps &
  UseControllerProps
export const FormInputDropdown: React.FC<DropDownFieldProps> = ({
  name,
  control,
  label,
  options,
  rules,
  optionsLabel,
  ...selectProps
}) => {
  const generateSingleOptions = () => {
    return optionsLabel?.map((option: TDropDownArraySoli) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    })
  }

  const generateSingleOptionsId = () => {
    return options?.map((option) => (
      <MenuItem key={option.id} value={option.nome}>
        {option.name ? option.name : option.nome}
      </MenuItem>
    ))
  }

  return (
    <FormControl fullWidth size={'small'}>
      <FormLabel htmlFor={label}>{label}</FormLabel>
      <Controller
        rules={rules}
        render={({field: {value, onChange}, fieldState: {error}}) => (
          <>
            <FormControl error={!!error}>
              <Select
                onChange={onChange}
                IconComponent={IoChevronDownOutline}
                MenuProps={{disableScrollLock: true}}
                value={value ?? ''}
                displayEmpty
                {...selectProps}>
                <MenuItem defaultValue={'Selecione'} value={''}>
                  Selecione
                </MenuItem>
                {!options ? generateSingleOptions() : generateSingleOptionsId()}
              </Select>
              <FormHelperText>{!!error && error.message}</FormHelperText>
            </FormControl>
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  )
}
