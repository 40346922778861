export const IconPiaui = () => {
  return (
    <svg
      width="137"
      height="81"
      viewBox="0 0 137 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.283 19.3282C37.5519 16.2439 43.7877 13.902 49.9395 13.4357C49.9877 12.3591 50.0353 11.2915 50.0827 10.2328C43.9227 10.8801 37.6983 12.9669 31.4386 15.8468C31.3868 17.0047 31.3349 18.1653 31.283 19.3282Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.0084 15.8568C44.2551 16.1865 38.4221 18.292 32.5561 21.1461C32.5046 22.2983 32.4528 23.4543 32.4009 24.6142C38.2736 21.6172 44.1142 19.3361 49.8616 19.141C49.9111 18.0375 49.9599 16.9427 50.0084 15.8568Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5087 32.0408C36.8021 27.9848 44.0703 24.4849 51.1609 24.9715C51.2116 23.8338 51.2622 22.7033 51.3124 21.5798C44.2144 21.2613 36.9559 24.5399 29.6671 28.4979C29.6142 29.6777 29.5616 30.8586 29.5087 32.0408Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.8085 33.5236C51.7565 34.6913 51.704 35.8644 51.6513 37.0436C36.1519 34.7734 19.6503 54.9374 4.15047 52.6787L4.81771 49.2717C20.1672 51.0558 36.4863 31.1309 51.8085 33.5236Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7383 40.3542C54.6852 41.5406 54.6322 42.7271 54.5794 43.9135C45.8279 40.545 36.6768 46.1201 27.5569 50.9959C18.4792 56.0001 9.43236 60.3104 0.675751 58.1261L1.33885 54.74C18.6922 59.9359 37.4259 34.2398 54.7383 40.3542Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.0695 46.1446C53.0163 47.3373 52.9628 48.5327 52.9092 49.7309C44.5091 47.6042 35.7753 52.9437 27.0858 57.2902C18.4187 61.8225 9.79558 65.3676 1.37527 64.3771L2.04143 60.9752C10.279 62.2229 18.7453 58.3464 27.2445 53.7375C35.9439 49.1692 44.6775 43.8352 53.0695 46.1446Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9065 73.7504C21.8568 74.8619 21.8074 75.9687 21.7582 77.0708C16.7723 78.6195 11.8108 79.628 6.84715 80.6814L7.45244 77.5901C12.2533 76.6936 17.0679 75.4889 21.9065 73.7504Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8256 55.5245C42.7892 54.8104 34.4973 59.8227 26.2629 63.5402C17.8365 67.6058 9.47057 70.3182 1.22786 70.2732L1.89835 66.8501C9.94872 67.3404 18.1599 64.2287 26.4209 60.007C34.668 56.0016 42.9644 50.8905 50.9896 51.8539L50.8256 55.5245Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6313 63.9786C34.4586 65.8144 29.2511 68.4261 24.0705 70.4357C17.51 73.1055 10.9926 74.8098 4.5097 75.7432L5.14347 72.5063C11.4516 71.8367 17.8185 69.8511 24.2245 66.988C29.4151 64.7533 34.6314 61.9445 39.8059 60.0739L39.6313 63.9786Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8113 5.08972C47.7663 6.09882 47.7207 7.11831 47.6746 8.14832C42.9823 8.92664 38.2576 10.4256 33.5122 12.3873L33.6631 9.01066C38.3995 7.24948 43.1157 5.93666 47.8113 5.08972Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9791 1.6532C42.2224 1.07373 44.4654 0.542011 46.7083 0C46.7001 0.985133 46.6917 1.98012 46.6834 2.98539C44.404 3.47837 42.1241 4.03934 39.8434 4.69197L39.9791 1.6532Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1078 30.7561C41.0195 30.301 31.612 36.9913 22.2851 41.8751L22.4449 38.2952C31.7722 33.4016 41.1703 26.9257 50.2616 27.315C50.2107 28.4539 50.1596 29.601 50.1078 30.7561Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.8129 54.549H62.3805L61.8771 55.6932H61.2034L63.2763 51.1158H63.9236L66.0032 55.6932H65.3163L64.8129 54.549ZM64.584 54.0258L63.5968 51.7829L62.6094 54.0258H64.584Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1732 56.1116C71.0249 56.2862 70.8528 56.419 70.6565 56.5106C70.4605 56.6019 70.2491 56.6481 70.0222 56.6481C69.7347 56.6481 69.4655 56.5795 69.2147 56.442C68.9642 56.3048 68.6777 56.0704 68.3549 55.7388C67.9233 55.717 67.5344 55.6015 67.1876 55.3925C66.8411 55.1832 66.571 54.9054 66.377 54.5588C66.1829 54.2125 66.086 53.8275 66.086 53.4047C66.086 52.9645 66.1917 52.5654 66.4031 52.208C66.6144 51.8504 66.9053 51.5704 67.276 51.3677C67.6465 51.1651 68.0627 51.0636 68.525 51.0636C68.9824 51.0636 69.3968 51.1651 69.7675 51.3677C70.1377 51.5704 70.4276 51.8495 70.6371 52.2046C70.8464 52.5603 70.9507 52.9601 70.9507 53.4047C70.9507 53.7796 70.8757 54.124 70.7251 54.4377C70.5747 54.7516 70.3633 55.0157 70.0909 55.2288C69.8187 55.4429 69.5057 55.5909 69.1526 55.6734C69.3052 55.8348 69.4524 55.9514 69.594 56.0234C69.7356 56.0953 69.8806 56.1314 70.0289 56.1314C70.3514 56.1314 70.6306 56.0006 70.8658 55.7388L71.1732 56.1116ZM66.9719 54.3037C67.1266 54.572 67.3403 54.7824 67.6127 54.9349C67.8853 55.0875 68.1891 55.1634 68.525 55.1634C68.8562 55.1634 69.1569 55.0875 69.4272 54.9349C69.6974 54.7824 69.9103 54.572 70.0647 54.3037C70.2194 54.0357 70.2968 53.7359 70.2968 53.4047C70.2968 53.0736 70.2194 52.7734 70.0647 52.5055C69.9103 52.2375 69.6974 52.0272 69.4272 51.8746C69.1569 51.7219 68.8562 51.6457 68.525 51.6457C68.1891 51.6457 67.8853 51.7219 67.6127 51.8746C67.3403 52.0272 67.1266 52.2375 66.9719 52.5055C66.8171 52.7734 66.7399 53.0736 66.7399 53.4047C66.7399 53.7359 66.8171 54.0357 66.9719 54.3037Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.313 55.2226C71.9731 54.8736 71.803 54.3723 71.803 53.7183V51.116H72.4569V53.6924C72.4569 54.6731 72.8862 55.1635 73.7449 55.1635C74.1635 55.1635 74.484 55.0426 74.7062 54.8005C74.9287 54.5587 75.0397 54.1893 75.0397 53.6924V51.116H75.6739V53.7183C75.6739 54.3766 75.5041 54.8791 75.1638 55.2256C74.8239 55.5724 74.3489 55.7454 73.7384 55.7454C73.128 55.7454 72.6529 55.5713 72.313 55.2226Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9075 51.116H77.5615V55.6933H76.9075V51.116Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.6026 51.685H80.0332V51.1158H83.8191V51.685H82.2499V55.6932H81.6026V51.685Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.7945 55.1244V55.6931H84.4728V51.1158H87.7033V51.685H85.1268V53.0906H87.4221V53.6463H85.1268V55.1244H87.7945Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9211 55.6932L92.9145 52.3582L91.2602 55.1375H90.9595L89.3052 52.378V55.6932H88.6771V51.1158H89.2135L91.1229 54.3332L93.006 51.1158H93.5422L93.5489 55.6932H92.9211Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4851 51.9793H96.0207V51.1158H100.009V51.9793H98.5447V55.6931H97.4851V51.9793Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.45 55.6932L102.567 54.4181H102.515H101.593V55.6932H100.534V51.1158H102.515C102.921 51.1158 103.273 51.1837 103.571 51.3189C103.87 51.4538 104.1 51.6457 104.261 51.8943C104.422 52.1426 104.503 52.4367 104.503 52.7766C104.503 53.1169 104.422 53.41 104.258 53.6562C104.094 53.9026 103.862 54.091 103.561 54.2218L104.588 55.6932H103.45ZM103.182 52.1852C103.017 52.048 102.775 51.9793 102.456 51.9793H101.593V53.5745H102.456C102.775 53.5745 103.017 53.5051 103.182 53.3652C103.348 53.2258 103.431 53.0296 103.431 52.7766C103.431 52.5196 103.348 52.3224 103.182 52.1852Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.522 54.7125H106.397L105.992 55.6933H104.906L106.946 51.116H107.992L110.039 55.6933H108.927L108.522 54.7125ZM108.189 53.9082L107.463 52.1557L106.737 53.9082H108.189Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.494 53.7283C114.642 53.9226 114.716 54.1609 114.716 54.4442C114.716 54.8456 114.561 55.1537 114.249 55.3695C113.937 55.5853 113.483 55.6932 112.886 55.6932H110.518V51.1158H112.755C113.313 51.1158 113.741 51.2229 114.04 51.4366C114.338 51.6501 114.487 51.94 114.487 52.3061C114.487 52.5285 114.434 52.7268 114.328 52.9013C114.221 53.0755 114.071 53.2128 113.88 53.3131C114.141 53.396 114.346 53.5344 114.494 53.7283ZM111.571 51.9138V52.9926H112.624C112.886 52.9926 113.084 52.947 113.219 52.8554C113.354 52.7639 113.422 52.6289 113.422 52.45C113.422 52.2715 113.354 52.1371 113.219 52.048C113.084 51.9586 112.886 51.9138 112.624 51.9138H111.571ZM113.438 54.7583C113.58 54.6666 113.651 54.5249 113.651 54.3332C113.651 53.954 113.37 53.7644 112.807 53.7644H111.571V54.8956H112.807C113.086 54.8956 113.296 54.8499 113.438 54.7583Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.488 54.7125H116.362L115.957 55.6933H114.871L116.912 51.116H117.958L120.004 55.6933H118.893L118.488 54.7125ZM118.154 53.9082L117.428 52.1557L116.702 53.9082H118.154Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.483 51.1158H121.543V54.8301H123.838V55.6931H120.483V51.1158Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.573 51.1158V55.6931H127.514V53.8164H125.435V55.6931H124.375V51.1158H125.435V52.9205H127.514V51.1158H128.573Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.596 55.4643C130.214 55.2594 129.916 54.9772 129.7 54.6176C129.484 54.2581 129.376 53.8539 129.376 53.4047C129.376 52.9557 129.484 52.5515 129.7 52.1919C129.916 51.8322 130.214 51.5501 130.596 51.3447C130.977 51.14 131.405 51.0377 131.88 51.0377C132.356 51.0377 132.783 51.14 133.162 51.3447C133.541 51.5501 133.84 51.8322 134.058 52.1919C134.276 52.5515 134.385 52.9557 134.385 53.4047C134.385 53.8539 134.276 54.2581 134.058 54.6176C133.84 54.9772 133.541 55.2594 133.162 55.4643C132.783 55.6694 132.356 55.7717 131.88 55.7717C131.405 55.7717 130.977 55.6694 130.596 55.4643ZM132.613 54.683C132.831 54.5588 133.002 54.3855 133.126 54.1631C133.25 53.9408 133.312 53.688 133.312 53.4047C133.312 53.1215 133.25 52.8684 133.126 52.646C133.002 52.4238 132.831 52.2506 132.613 52.1261C132.395 52.0019 132.151 51.94 131.88 51.94C131.61 51.94 131.366 52.0019 131.148 52.1261C130.93 52.2506 130.759 52.4238 130.635 52.646C130.51 52.8684 130.449 53.1215 130.449 53.4047C130.449 53.688 130.51 53.9408 130.635 54.1631C130.759 54.3855 130.93 54.5588 131.148 54.683C131.366 54.8071 131.61 54.8693 131.88 54.8693C132.151 54.8693 132.395 54.8071 132.613 54.683Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.873 55.6049C134.786 55.5198 134.742 55.4121 134.742 55.2815C134.742 55.1551 134.786 55.0492 134.873 54.9641C134.96 54.8791 135.064 54.8367 135.187 54.8367C135.309 54.8367 135.411 54.8779 135.494 54.9608C135.577 55.0437 135.618 55.1507 135.618 55.2815C135.618 55.4121 135.576 55.5198 135.491 55.6049C135.406 55.6901 135.304 55.7323 135.187 55.7323C135.064 55.7323 134.96 55.6901 134.873 55.6049Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.8129 62.118H62.3805L61.8771 63.2623H61.2034L63.2763 58.6849H63.9236L66.0032 63.2623H65.3163L64.8129 62.118ZM64.584 61.5949L63.5968 59.352L62.6094 61.5949H64.584Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1732 63.6807C71.0249 63.8552 70.8528 63.9881 70.6565 64.0797C70.4605 64.171 70.2491 64.2172 70.0222 64.2172C69.7347 64.2172 69.4655 64.1484 69.2147 64.0111C68.9642 63.8739 68.6777 63.6395 68.3549 63.3079C67.9233 63.2861 67.5344 63.1707 67.1876 62.9613C66.8411 62.7522 66.571 62.4745 66.377 62.1279C66.1829 61.7816 66.086 61.3966 66.086 60.9738C66.086 60.5335 66.1917 60.1345 66.4031 59.7769C66.6144 59.4195 66.9053 59.1395 67.276 58.9368C67.6465 58.7342 68.0627 58.6327 68.525 58.6327C68.9824 58.6327 69.3968 58.7342 69.7675 58.9368C70.1377 59.1395 70.4276 59.4184 70.6371 59.7737C70.8464 60.1294 70.9507 60.5292 70.9507 60.9738C70.9507 61.3487 70.8757 61.693 70.7251 62.0067C70.5747 62.3207 70.3633 62.5848 70.0909 62.7979C69.8187 63.0119 69.5057 63.16 69.1526 63.2425C69.3052 63.4039 69.4524 63.5205 69.594 63.5923C69.7356 63.6644 69.8806 63.7005 70.0289 63.7005C70.3514 63.7005 70.6306 63.5697 70.8658 63.3079L71.1732 63.6807ZM66.9719 61.8728C67.1266 62.141 67.3403 62.3513 67.6127 62.504C67.8853 62.6566 68.1891 62.7325 68.525 62.7325C68.8562 62.7325 69.1569 62.6566 69.4272 62.504C69.6974 62.3513 69.9103 62.141 70.0647 61.8728C70.2194 61.6047 70.2968 61.305 70.2968 60.9738C70.2968 60.6427 70.2194 60.3425 70.0647 60.0746C69.9103 59.8066 69.6974 59.5963 69.4272 59.4436C69.1569 59.291 68.8562 59.2146 68.525 59.2146C68.1891 59.2146 67.8853 59.291 67.6127 59.4436C67.3403 59.5963 67.1266 59.8066 66.9719 60.0746C66.8171 60.3425 66.7399 60.6427 66.7399 60.9738C66.7399 61.305 66.8171 61.6047 66.9719 61.8728Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.313 62.7916C71.9731 62.4426 71.803 61.9413 71.803 61.2873V58.6849H72.4569V61.2614C72.4569 62.2422 72.8862 62.7325 73.7449 62.7325C74.1635 62.7325 74.484 62.6116 74.7062 62.3696C74.9287 62.1278 75.0397 61.7584 75.0397 61.2614V58.6849H75.6739V61.2873C75.6739 61.9456 75.5041 62.4481 75.1638 62.7944C74.8239 63.1414 74.3489 63.3144 73.7384 63.3144C73.128 63.3144 72.6529 63.1402 72.313 62.7916Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9075 58.6849H77.5615V63.2623H76.9075V58.6849Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.6026 59.2541H80.0332V58.6849H83.8191V59.2541H82.2499V63.2623H81.6026V59.2541Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.7945 62.6935V63.2623H84.4728V58.6849H87.7033V59.2541H85.1268V60.6599H87.4221V61.2154H85.1268V62.6935H87.7945Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9211 63.2623L92.9145 59.9275L91.2602 62.7066H90.9595L89.3052 59.947V63.2623H88.6771V58.6849H89.2135L91.1229 61.9023L93.006 58.6849H93.5422L93.5489 63.2623H92.9211Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5964 59.5353V60.7449H99.7148V61.5946H97.5964V63.2623H96.5368V58.6849H99.9963V59.5353H97.5964Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.199 62.7978C100.835 62.4364 100.653 61.9197 100.653 61.2483V58.6849H101.712V61.2089C101.712 62.0287 102.053 62.4382 102.732 62.4382C103.064 62.4382 103.317 62.3393 103.491 62.141C103.665 61.9423 103.753 61.6321 103.753 61.2089V58.6849H104.799V61.2483C104.799 61.9197 104.617 62.4364 104.253 62.7978C103.889 63.16 103.38 63.3408 102.726 63.3408C102.072 63.3408 101.563 63.16 101.199 62.7978Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.77 59.5484H105.306V58.6849H109.294V59.5484H107.83V63.2623H106.77V59.5484Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.347 62.7978C109.983 62.4364 109.801 61.9197 109.801 61.2483V58.6849H110.86V61.2089C110.86 62.0287 111.201 62.4382 111.88 62.4382C112.211 62.4382 112.465 62.3393 112.639 62.141C112.813 61.9423 112.9 61.6321 112.9 61.2089V58.6849H113.947V61.2483C113.947 61.9197 113.765 62.4364 113.401 62.7978C113.037 63.16 112.528 63.3408 111.874 63.3408C111.22 63.3408 110.711 63.16 110.347 62.7978Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.905 63.2623L117.022 61.9872H116.97H116.048V63.2623H114.988V58.6849H116.97C117.375 58.6849 117.727 58.7528 118.025 58.888C118.324 59.0229 118.554 59.2145 118.715 59.4634C118.877 59.7117 118.957 60.0058 118.957 60.3458C118.957 60.6859 118.876 60.9793 118.712 61.2253C118.549 61.4718 118.316 61.6602 118.016 61.7908L119.042 63.2623H117.905ZM117.636 59.7543C117.471 59.617 117.229 59.5484 116.911 59.5484H116.048V61.1436H116.911C117.229 61.1436 117.471 61.0742 117.636 60.9342C117.802 60.7949 117.885 60.5989 117.885 60.3458C117.885 60.0886 117.802 59.8915 117.636 59.7543Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.694 63.0334C120.312 62.8287 120.013 62.5463 119.798 62.1867C119.582 61.8272 119.474 61.4228 119.474 60.9738C119.474 60.5248 119.582 60.1206 119.798 59.7609C120.013 59.4014 120.312 59.1191 120.694 58.9138C121.075 58.7091 121.503 58.6068 121.979 58.6068C122.453 58.6068 122.881 58.7091 123.26 58.9138C123.639 59.1191 123.938 59.4014 124.156 59.7609C124.374 60.1206 124.483 60.5248 124.483 60.9738C124.483 61.4228 124.374 61.8272 124.156 62.1867C123.938 62.5463 123.639 62.8287 123.26 63.0334C122.881 63.2387 122.453 63.3408 121.979 63.3408C121.503 63.3408 121.075 63.2387 120.694 63.0334ZM122.711 62.252C122.929 62.128 123.1 61.9545 123.224 61.7321C123.348 61.5099 123.41 61.2572 123.41 60.9738C123.41 60.6905 123.348 60.4375 123.224 60.2151C123.1 59.9928 122.929 59.8197 122.711 59.6952C122.493 59.571 122.249 59.509 121.979 59.509C121.708 59.509 121.464 59.571 121.246 59.6952C121.028 59.8197 120.857 59.9928 120.733 60.2151C120.609 60.4375 120.547 60.6905 120.547 60.9738C120.547 61.2572 120.609 61.5099 120.733 61.7321C120.857 61.9545 121.028 62.128 121.246 62.252C121.464 62.3762 121.708 62.4382 121.979 62.4382C122.249 62.4382 122.493 62.3762 122.711 62.252Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.97 63.1739C124.883 63.0887 124.839 62.9811 124.839 62.8503C124.839 62.7241 124.883 62.6182 124.97 62.533C125.057 62.4481 125.162 62.4056 125.284 62.4056C125.406 62.4056 125.508 62.4469 125.591 62.5298C125.674 62.6127 125.716 62.7197 125.716 62.8503C125.716 62.9811 125.673 63.0887 125.588 63.1739C125.503 63.2591 125.402 63.3013 125.284 63.3013C125.162 63.3013 125.057 63.2591 124.97 63.1739Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7326 18.8127C64.1137 18.6071 64.55 18.5041 65.0407 18.5041C65.7903 18.5041 66.4303 18.7695 66.9613 19.3004L67.7773 18.5041C67.452 18.1328 67.0507 17.8509 66.5731 17.6585C66.0956 17.4662 65.5648 17.3701 64.9812 17.3701C64.2649 17.3701 63.6199 17.5241 63.0457 17.8326C62.4722 18.1412 62.0224 18.5674 61.6975 19.1113C61.3724 19.6554 61.2101 20.2687 61.2101 20.9523C61.2101 21.6353 61.3724 22.2492 61.6975 22.7926C62.0224 23.3367 62.4705 23.7628 63.041 24.0713C63.6113 24.3799 64.2514 24.5342 64.9612 24.5342C65.4654 24.5342 65.9564 24.4576 66.434 24.3053C66.9117 24.1527 67.3297 23.9337 67.6879 23.6486V20.8726H66.464V23.0417C66.0524 23.2805 65.5749 23.3999 65.031 23.3999C64.5468 23.3999 64.1137 23.2936 63.7326 23.0814C63.351 22.8689 63.0524 22.5772 62.837 22.2056C62.6212 21.8343 62.5134 21.4163 62.5134 20.9523C62.5134 20.4742 62.6212 20.05 62.837 19.6783C63.0524 19.3071 63.351 19.0185 63.7326 18.8127Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9609 17.8327C74.3901 17.5242 73.75 17.37 73.0404 17.37C72.3305 17.37 71.6905 17.5242 71.1202 17.8327C70.5497 18.1413 70.1016 18.5692 69.7767 19.1159C69.4518 19.6632 69.2892 20.2754 69.2892 20.9522C69.2892 21.6287 69.4518 22.2407 69.7767 22.7878C70.1016 23.3353 70.5497 23.7627 71.1202 24.0712C71.6905 24.3797 72.3305 24.5343 73.0404 24.5343C73.75 24.5343 74.3901 24.3797 74.9609 24.0712C75.5311 23.7627 75.979 23.3366 76.3041 22.7927C76.629 22.249 76.7919 21.6352 76.7919 20.9522C76.7919 20.2687 76.629 19.6552 76.3041 19.1113C75.979 18.5673 75.5311 18.1413 74.9609 17.8327ZM75.1698 22.2108C74.9576 22.5856 74.6658 22.8773 74.2941 23.0863C73.9225 23.2954 73.5048 23.3997 73.0404 23.3997C72.576 23.3997 72.1583 23.2954 71.7869 23.0863C71.4152 22.8773 71.1236 22.5856 70.911 22.2108C70.6989 21.8361 70.5926 21.4162 70.5926 20.9522C70.5926 20.4877 70.6989 20.0682 70.911 19.6933C71.1236 19.3186 71.4152 19.0266 71.7869 18.8178C72.1583 18.6089 72.576 18.5042 73.0404 18.5042C73.5048 18.5042 73.9225 18.6089 74.2941 18.8178C74.6658 19.0266 74.9576 19.3186 75.1698 19.6933C75.3821 20.0682 75.4884 20.4877 75.4884 20.9522C75.4884 21.4162 75.3821 21.8361 75.1698 22.2108Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.0798 22.8428L78.7612 17.4695H77.3587L80.4032 24.4347H81.6767L84.7116 17.4695H83.4183L81.0798 22.8428Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1402 21.4298H90.5031V20.365H87.1402V18.5541H90.9311V17.4694H85.8465V24.4346H91.0702V23.3499H87.1402V21.4298Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.3589 21.3002C98.6142 20.9223 98.742 20.4711 98.742 19.9472C98.742 19.4363 98.6225 18.9953 98.3838 18.6235C98.145 18.2522 97.8015 17.9672 97.3538 17.7682C96.9061 17.5692 96.3804 17.4694 95.7769 17.4694H92.9111V24.4346H94.2047V22.4047H95.7769C95.8963 22.4047 95.9856 22.4016 96.0451 22.3948L97.4681 24.4346H98.8613L97.2591 22.1461C97.7367 21.9604 98.1034 21.6784 98.3589 21.3002ZM95.717 21.3399H94.2047V18.564H95.717C96.2806 18.564 96.7086 18.6835 97.0008 18.9221C97.2924 19.1609 97.4386 19.5027 97.4386 19.9472C97.4386 20.3914 97.2924 20.735 97.0008 20.9772C96.7086 21.2192 96.2806 21.3399 95.717 21.3399Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.488 22.186L101.647 17.4695H100.582V24.4347H101.866V19.7182L105.707 24.4347H106.771V17.4695H105.488V22.186Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.214 17.8327C113.643 17.5242 113.003 17.37 112.294 17.37C111.584 17.37 110.944 17.5242 110.374 17.8327C109.803 18.1413 109.355 18.5692 109.03 19.1159C108.705 19.6632 108.542 20.2754 108.542 20.9522C108.542 21.6287 108.705 22.2407 109.03 22.7878C109.355 23.3353 109.803 23.7627 110.374 24.0712C110.944 24.3797 111.584 24.5343 112.294 24.5343C113.003 24.5343 113.643 24.3797 114.214 24.0712C114.784 23.7627 115.232 23.3366 115.557 22.7927C115.882 22.249 116.045 21.6352 116.045 20.9522C116.045 20.2687 115.882 19.6552 115.557 19.1113C115.232 18.5673 114.784 18.1413 114.214 17.8327ZM114.423 22.2108C114.211 22.5856 113.919 22.8773 113.547 23.0863C113.176 23.2954 112.758 23.3997 112.294 23.3997C111.829 23.3997 111.412 23.2954 111.04 23.0863C110.668 22.8773 110.377 22.5856 110.164 22.2108C109.952 21.8361 109.846 21.4162 109.846 20.9522C109.846 20.4877 109.952 20.0682 110.164 19.6933C110.377 19.3186 110.668 19.0266 111.04 18.8178C111.412 18.6089 111.829 18.5042 112.294 18.5042C112.758 18.5042 113.176 18.6089 113.547 18.8178C113.919 19.0266 114.211 19.3186 114.423 19.6933C114.635 20.0682 114.742 20.4877 114.742 20.9522C114.742 21.4162 114.635 21.8361 114.423 22.2108Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.985 17.9025C125.408 17.6138 124.748 17.4695 124.005 17.4695H120.96V24.4347H124.005C124.748 24.4347 125.408 24.2905 125.985 24.0021C126.562 23.7133 127.009 23.3053 127.328 22.7778C127.646 22.2507 127.806 21.6419 127.806 20.9522C127.806 20.2621 127.646 19.6536 127.328 19.1264C127.009 18.5989 126.562 18.1909 125.985 17.9025ZM126.189 22.2109C125.98 22.5723 125.681 22.8512 125.293 23.0466C124.905 23.2422 124.456 23.3401 123.945 23.3401H122.254V18.5641H123.945C124.456 18.5641 124.905 18.662 125.293 18.8576C125.681 19.0533 125.98 19.332 126.189 19.6933C126.397 20.0553 126.502 20.4743 126.502 20.9522C126.502 21.4299 126.397 21.8493 126.189 22.2109Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.094 19.1113C135.769 18.5674 135.321 18.1412 134.751 17.8326C134.18 17.5241 133.541 17.3701 132.831 17.3701C132.121 17.3701 131.481 17.5241 130.911 17.8326C130.34 18.1412 129.892 18.5691 129.567 19.116C129.242 19.6634 129.08 20.2753 129.08 20.9523C129.08 21.6287 129.242 22.2406 129.567 22.7879C129.892 23.3352 130.34 23.7628 130.911 24.0713C131.481 24.3799 132.121 24.5342 132.831 24.5342C133.541 24.5342 134.18 24.3799 134.751 24.0713C135.321 23.7628 135.769 23.3367 136.094 22.7926C136.419 22.2492 136.582 21.6353 136.582 20.9523C136.582 20.2687 136.419 19.6554 136.094 19.1113ZM134.96 22.2108C134.748 22.5857 134.456 22.8775 134.084 23.0862C133.713 23.2955 133.295 23.3999 132.831 23.3999C132.366 23.3999 131.949 23.2955 131.577 23.0862C131.206 22.8775 130.914 22.5857 130.701 22.2108C130.489 21.8361 130.383 21.4163 130.383 20.9523C130.383 20.4877 130.489 20.0683 130.701 19.6932C130.914 19.3187 131.206 19.0267 131.577 18.8178C131.949 18.6088 132.366 18.5041 132.831 18.5041C133.295 18.5041 133.713 18.6088 134.084 18.8178C134.456 19.0267 134.748 19.3187 134.96 19.6932C135.173 20.0683 135.279 20.4877 135.279 20.9523C135.279 21.4163 135.173 21.8361 134.96 22.2108Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2131 28.1696C76.56 28.7761 77.5993 29.642 78.331 30.7678C79.0622 31.8938 79.4281 33.2174 79.4281 34.7377C79.4281 36.258 79.0622 37.5766 78.331 38.6925C77.5993 39.8091 76.56 40.67 75.2131 41.2766C73.8658 41.8826 72.2779 42.1859 70.4495 42.1859H66.9278V47.4689H61.2115V27.2604H70.4495C72.2779 27.2604 73.8658 27.5636 75.2131 28.1696ZM72.7591 36.9171C73.3554 36.4074 73.654 35.6808 73.654 34.7377C73.654 33.7947 73.3554 33.0631 72.7591 32.5434C72.1623 32.024 71.277 31.7639 70.1031 31.7639H66.9278V37.6823H70.1031C71.277 37.6823 72.1623 37.4275 72.7591 36.9171Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4672 27.2604H86.1833V47.4689H80.4672V27.2604Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.216 43.5425H94.6708L93.0829 47.4689H87.2511L96.1719 27.2604H101.801L110.751 47.4689H104.804L103.216 43.5425ZM101.541 39.3276L98.9431 32.8609L96.3449 39.3276H101.541Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.948 45.419C110.284 43.7835 109.451 41.4642 109.451 38.4617V27.2604H115.167V38.2883C115.167 41.522 116.447 43.1383 119.007 43.1383C121.547 43.1383 122.818 41.522 122.818 38.2883V27.2604H128.447V38.4617C128.447 41.4642 127.614 43.7835 125.95 45.419C124.285 47.0552 121.952 47.873 118.949 47.873C115.947 47.873 113.613 47.0552 111.948 45.419Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.722 27.1982H130.006V30.8716L135.722 27.1982Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.006 32.5321V47.4068H135.722V28.8586L130.006 32.5321Z"
        fill="white"
      />
    </svg>
  )
}
