import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'
export function useGetFieldsCustom({
  idServico,
  enabled,
}: {
  idServico: string
  enabled?: boolean
}) {
  return useQuery({
    queryKey: [QueryKeys.GetCustomFields, {idServico}],
    queryFn: () => {
      return serviceDomainService.getCustomFields(idServico)
    },
    enabled,
  })
}
