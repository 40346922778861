import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined'
import {LoadingButton} from '@mui/lab'
import {Box, Button, Divider, IconButton} from '@mui/material'
import {FaArrowRight} from 'react-icons/fa'

interface ICard {
  instituicao: string
  tipoServico: string
  descricao: string
  cardClicked: string
  btnSolicitar: () => void
  isFavorite: boolean
  toggleFavorite: () => void
  isLoadingFavoritar: boolean
}

const Card = ({
  instituicao,
  tipoServico,
  descricao,
  cardClicked,
  btnSolicitar,
  isFavorite,
  toggleFavorite,
  isLoadingFavoritar,
}: ICard) => {
  return (
    <Box className="py-[40px] px-[20px] flex flex-col gap-[20px] max-w-[370px] lg:w-[370px] h-[370px] rounded-md border-[1px] border-[#ccc]">
      <p className="font-[700] text-[16px]">{instituicao?.toUpperCase()}</p>
      <Box className="flex justify-between items-center">
        <p className="font-[700] text-[22px]">{tipoServico}</p>
        {isFavorite ? (
          <LoadingButton
            loading={tipoServico === cardClicked && isLoadingFavoritar}
            loadingPosition="center"
            onClick={toggleFavorite}
            color="primary">
            {tipoServico === cardClicked && isLoadingFavoritar ? null : (
              <BookmarkOutlinedIcon
                color="warning"
                sx={{width: 25, height: 25}}
              />
            )}
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={tipoServico === cardClicked && isLoadingFavoritar}
            loadingPosition="center"
            onClick={toggleFavorite}
            color="primary">
            {tipoServico === cardClicked && isLoadingFavoritar ? null : (
              <BookmarkBorderOutlinedIcon
                color="action"
                sx={{width: 25, height: 25}}
              />
            )}
          </LoadingButton>
        )}
      </Box>
      <Divider />
      <p>{descricao}</p>
      <Button
        variant="outlined"
        color="primary"
        sx={{textTransform: 'none'}}
        className="flex items-center text-[16px] gap-[10px] max-w-[130px]"
        onClick={btnSolicitar}>
        Solicitar <FaArrowRight />
      </Button>
    </Box>
  )
}

export default Card
