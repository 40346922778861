export const IconUser = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.1582 10.7976C2.04181 9.32377 3.97839 8.31507 6.99972 8.31507C10.0211 8.31507 11.9576 9.32377 12.8412 10.7976M9.39972 3.19761C9.39972 4.52309 8.3252 5.59761 6.99972 5.59761C5.67424 5.59761 4.59972 4.52309 4.59972 3.19761C4.59972 1.87212 5.67424 0.797607 6.99972 0.797607C8.3252 0.797607 9.39972 1.87212 9.39972 3.19761Z"
        stroke="#111928"
        strokeLinecap="round"
      />
    </svg>
  )
}
