import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'
import {useAuth} from 'services/auth'

import {serviceDomainService} from '../serviceDomainService'

export function useGetServices({
  identificador,
  nome,
  favorito,
}: {
  identificador?: string
  nome?: string
  favorito?: boolean | string
}) {
  const {userData} = useAuth()
  return useQuery({
    queryKey: [QueryKeys.GetAllServices, {identificador, nome, favorito}],
    queryFn: () => {
      return serviceDomainService.getAllServices({
        email: userData.email,
        favorito,
        identificador,
        nome,
      })
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}
