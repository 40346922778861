import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'

export const useGetStatus = () => {
  const {isLoading: statusLoading, ...rest} = useQuery({
    queryKey: [QueryKeys.GetStatus],
    queryFn: () => {
      return serviceDomainService.getStatus()
    },
    retry: false,
    staleTime: Infinity,
    enabled: true,
  })
  return {statusLoading, ...rest}
}
