import {Box} from '@mui/material'
import {IoChevronDownOutline} from 'react-icons/io5'

interface IDisabledSelectField {
  label: string
  value: string
}

// Estilos personalizados

const DisabledSelectField = ({label, value}: IDisabledSelectField) => {
  return (
    <Box className="flex flex-col gap-[10px] w-full">
      <p className="text-[#6B7280]">{label}</p>
      <Box className="flex">
        <input
          value={value}
          className="bg-[#F3F4F6] w-full text-[#9CA3AF] px-[16px] pr-[35px] py-[12px] rounded-[6px]"
          disabled
        />
        <Box className="relative">
          <IoChevronDownOutline
            className="absolute mr-[10px] ml-[20px] h-[46px] right-0"
            size={18}
            color="#6B7280"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DisabledSelectField
