import {AxiosError} from 'axios'

import {serviceDomainApi} from './serviceDomainApi'
import {
  IGetDetalhesDaSolicitacaoRequest,
  IGetDetalhesDaSolicitacaoResponse,
  IGetMinhasSolicitacoesRequest,
  IGetMinhasSolicitacoesResponse,
  IGetServicesResponse,
  IGetStatus,
  ICustomField,
  IAprovarSolicitacao,
  ISolicitacao,
  IssueDataSolicitacao,
  IPutModalComentarioRequest,
  IGetFileRequest,
  IGetFileResponse,
  IPostFavoritoParams,
  FileData,
  EntidadeSolicitante,
  Categoria,
} from './serviceDomainTypes'

async function getAllServices(params: {
  email: string
  identificador?: string
  nome?: string
  favorito?: boolean | string
}): Promise<IGetServicesResponse[]> {
  try {
    const {data} = await serviceDomainApi.getAllServices(params)
    return data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function getMinhasSolicitacoes({
  perfilId,
  id,
  statusId,
  gre,
  servico,
  subject,
  dataAbertura,
  dataAtualizacao,
  categoria,
  escola,
}: IGetMinhasSolicitacoesRequest): Promise<IGetMinhasSolicitacoesResponse[]> {
  try {
    const {data} = await serviceDomainApi.getMinhasSolicitacoes({
      perfilId,
      id,
      statusId,
      gre,
      servico,
      subject,
      dataAbertura,
      dataAtualizacao,
      categoria,
      escola,
    })
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getStatus(): Promise<IGetStatus[]> {
  try {
    const {data} = await serviceDomainApi.getStatus()
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getDetalhesDaSolicitacao({
  solicitacaoId,
}: IGetDetalhesDaSolicitacaoRequest): Promise<IGetDetalhesDaSolicitacaoResponse> {
  try {
    const {data} = await serviceDomainApi.getDetalhesDaSolicitacao({
      solicitacaoId,
    })
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getCustomFields(idServico: string): Promise<ICustomField[]> {
  try {
    const {data} = await serviceDomainApi.getCustomFields(idServico)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getSolicitacao(idSolicitacao: string): Promise<ISolicitacao> {
  try {
    const {data} = await serviceDomainApi.getSolicitacao(idSolicitacao)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function AbrirSolicitacao(dataPost: IssueDataSolicitacao): Promise<any> {
  try {
    const {data} = await serviceDomainApi.AbrirSolicitacao(dataPost)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function postFavoritar(params: IPostFavoritoParams): Promise<any> {
  try {
    const {data} = await serviceDomainApi.postFavoritar(params)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function deleteFavoritar(params: IPostFavoritoParams): Promise<any> {
  try {
    const {data} = await serviceDomainApi.deleteFavoritar(params)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function AprovarSolicitacao(dataPost: IAprovarSolicitacao): Promise<any> {
  try {
    const {data} = await serviceDomainApi.AprovarSolicitacao(dataPost)
    return data
  } catch (error: any) {
    throw new AxiosError(error)
  }
}

async function putModalComentario(
  dataPut: IPutModalComentarioRequest,
): Promise<any> {
  try {
    const {data} = await serviceDomainApi.putModalComentario(dataPut)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getFile(dataGet: IGetFileRequest): Promise<IGetFileResponse> {
  try {
    const {data} = await serviceDomainApi.getFile(dataGet)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}
async function getFileDownload(idFile: string): Promise<FileData> {
  try {
    const {data} = await serviceDomainApi.getFileDownload(idFile)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getEntidadesFromGre(
  idGre: string,
  isGRE: string,
): Promise<EntidadeSolicitante> {
  try {
    const {data} = await serviceDomainApi.getEntidadeForGre(idGre, isGRE)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getPendenciasListaDeAprovacao(idGre: string): Promise<number> {
  try {
    const {data} = await serviceDomainApi.getPendenciasListaDeAprovacao(idGre)
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getCategorias(): Promise<Array<string>> {
  try {
    const {data} = await serviceDomainApi.getCategorias()
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

async function getServiceCategory(): Promise<Array<Categoria>> {
  try {
    const {data} = await serviceDomainApi.getServiceCategory()
    return data
  } catch (e: any) {
    throw new AxiosError(e)
  }
}

export const serviceDomainService = {
  getAllServices,
  getMinhasSolicitacoes,
  getStatus,
  getDetalhesDaSolicitacao,
  AprovarSolicitacao,
  AbrirSolicitacao,
  getSolicitacao,
  getCustomFields,
  putModalComentario,
  getFile,
  postFavoritar,
  getFileDownload,
  deleteFavoritar,
  getEntidadesFromGre,
  getPendenciasListaDeAprovacao,
  getCategorias,
  getServiceCategory,
}
