import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'

export function useGetServiceCategory() {
  return useQuery({
    queryKey: [QueryKeys.getServiceCategory],
    queryFn: () => {
      return serviceDomainService.getServiceCategory()
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })
}
