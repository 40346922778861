/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'

import {zodResolver} from '@hookform/resolvers/zod'
import {LoadingButton} from '@mui/lab'
import {Box, Button, Modal} from '@mui/material'
import {usePutModalComentario} from 'domains/ServiceDomain/useCases/usePutModalComentario'
import {readFileAsBase64} from 'pages/home/abrirSolicitacoes/abrirSolicitacoes'
import {useForm} from 'react-hook-form'
import {AiOutlineClose} from 'react-icons/ai'
import {BsCheck, BsTrash} from 'react-icons/bs'
import {toast} from 'react-toastify'

import FileField from '../fileField/FileField'
import {TextAreaTail} from '../textarea/textarea'

import {modalComentarioSchema} from './modalComentarioSchema'

interface IModalComentario {
  open: boolean
  id: string
  onClose: (is_inserted: boolean) => void
}

const ModalComentario = ({open, id, onClose}: IModalComentario) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: {errors},
  } = useForm<any>({
    resolver: zodResolver(modalComentarioSchema),
  })

  const {cadastrarComentario, isLoadingComentario} = usePutModalComentario({
    onSuccess: () => {
      onClose(true)
      toast.success('Comentário adicionado com sucesso')
    },
  })

  const onSubmit = async (data: any) => {
    const result = data.file ? await readFileAsBase64(data.file) : null
    if (result)
      cadastrarComentario({
        id,
        comentario: data.comentario,
        file: data.file,
        base64: result,
      })
    else cadastrarComentario({id, comentario: data.comentario})
  }

  useEffect(() => {
    reset({comentario: '', file: undefined})
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="flex justify-center p-[20px] items-center">
      <Box
        maxWidth={'1015px'}
        className="relative w-[1015px] max-h-[620px] h-full overflow-y-auto bg-white rounded min-[600px]:p-[40px] max-[600px]:p-[20px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-[20px] justify-between">
          <Box className="flex flex-col gap-[20px]">
            <AiOutlineClose
              size={35}
              color="black"
              className="hover:cursor-pointer"
              onClick={() => onClose(false)}
            />
            <h2 className="font-bold text-[30px]">
              Adicionar comentário na solicitação
            </h2>
            <TextAreaTail
              control={control}
              label="Comentário"
              maxLength={1000}
              name="comentario"
              placeholder="Adicione um comentário na solicitação"
            />
            <Box className="flex flex-col gap-[20px]">
              <Box className="flex flex-col gap-[5px]">
                <p className="text-[#424242] font-[500]">Anexos</p>
                <p className="text-[#424242] font-[500]">
                  Tipos de arquivos aceitos: pdf, png, jpg, tiff | Tamanho
                  máximo do arquivo: 2M
                </p>
              </Box>
              <Box className="flex flex-col gap-[10px]">
                <Box className="flex items-center gap-[10px]">
                  <FileField
                    clearErrors={clearErrors}
                    setValue={setValue}
                    error={errors.file ?? null}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="flex items-center justify-end mt-[20px]">
            <Box className="flex gap-[10px]">
              <LoadingButton
                loading={isLoadingComentario}
                loadingPosition="start"
                className="w-[120px] flex items-center max-h-[58px]"
                variant="contained"
                size="small"
                type="submit"
                sx={{textTransform: 'none'}}
                startIcon={<BsCheck size={25} color="white" />}>
                <p className="text-[white] text-[16px]">Salvar</p>
              </LoadingButton>
              <Button
                className="w-[150px] flex items-center max-h-[58px]"
                variant="contained"
                size="small"
                color="error"
                sx={{textTransform: 'none'}}
                startIcon={<AiOutlineClose size={20} color="white" />}
                onClick={() => onClose(false)}>
                <p className="text-[white] text-[16px]">Cancelar</p>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

export default ModalComentario
