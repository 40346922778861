import {QueryKeys} from 'infra'
import {useQuery} from 'react-query'

import {serviceDomainService} from '../serviceDomainService'
// Import your service domain service

export function useGetSolicitacao(idSolicitacao: string) {
  return useQuery({
    queryKey: [QueryKeys.GetCustomFields],
    queryFn: async () => {
      try {
        const data = await serviceDomainService.getSolicitacao(idSolicitacao)
        return data
      } catch (error) {
        throw new Error('Failed to fetch solicitacao')
      }
    },
  })
}
