import {useEffect, useMemo} from 'react'

import {makeStyles} from '@mui/styles'
import {Controller, UseControllerProps, FieldValues} from 'react-hook-form'
import {masks} from 'utils/masks'

import {TextFieldCom, TextFieldProps} from '../textfield/textfield'

export function TextFieldRHF<FormType extends FieldValues>({
  name,
  rules,
  control,
  regex = '',
  ...textFieldParams
}: {name: string} & TextFieldProps &
  UseControllerProps<FormType> & {regex?: string}) {
  const typeMask = useMemo(() => {
    // no includes colocar a regex e passar para cada uma consta a corresposndete
    const hasRegex = !!regex
    const isCpf =
      String(name).toLocaleLowerCase().includes('cpf') ||
      (hasRegex && regex.includes(regex))
    const isRg =
      String(name).toLocaleLowerCase().includes('rg') ||
      (hasRegex && regex.includes(regex))

    if (isCpf) {
      return 'cpf'
    }

    if (isRg) {
      return 'rg'
    }

    return ''
  }, [name, regex])

  const handleOnChange = (
    newValue: string | number,
    onChange: (...event: any[]) => void,
  ) => {
    const hasMask = !!typeMask

    if (!hasMask) {
      return onChange(newValue)
    }

    return masks[typeMask].output(newValue, onChange)
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        const newValue = value || ''
        return (
          <TextFieldCom
            helperText={error?.message}
            {...textFieldParams}
            error={!!error?.message}
            value={typeMask ? masks[typeMask].input(newValue) : newValue}
            onChange={(valueChanged: any) => {
              handleOnChange(valueChanged, onChange)
            }}
          />
        )
      }}
    />
  )
}
