import React, {useEffect, useState} from 'react'

import {zodResolver} from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import {LoadingButton} from '@mui/lab'
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormLabel,
  Typography,
} from '@mui/material'
import {blue, red} from '@mui/material/colors'
import BasicBreadcrumbs from 'components/atoms/bread/breadcrumbs'
import CustomButton from 'components/atoms/Button/ButtonComponent'
import {DisabledRHFTextField} from 'components/atoms/disabledRHFTextField/DisabledTextField'
import DisabledTextField from 'components/atoms/disabledTextField/DisabledTextField'
import {FormInputDropdown} from 'components/atoms/dropdown/dropdownInput'
import {TextFieldRHF} from 'components/atoms/form/TextFieldRHF'
import {RHFTextFieldNumber} from 'components/atoms/inputNumber/inputNumber'
import {TextAreaTail} from 'components/atoms/textarea/textarea'
import FileInput from 'components/atoms/upload/upload'
import Navbar from 'components/molecules/navbar/Navbar'
import {ILotacaoUser, ISignInCredentials, ISignResponse} from 'domains/Auth'
import {UseAbrirSolicitacao} from 'domains/ServiceDomain/useCases/useAbrirSolicitacao'
import {useGetEntidadeSolicitante} from 'domains/ServiceDomain/useCases/useGetEntidadeSolicitante'
import {useGetFieldsCustom} from 'domains/ServiceDomain/useCases/useGetFieldsCustom'
import {useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from 'services/auth'

import {generateSchema} from './SolicitaSchema'

const defaultValues = {
  subject: '',
  description: '',
}

export interface PossibleValue {
  value: string
  label: string
}

interface UserData {
  registrationNumber: string
  name: string
  profileDescription: string
  email: string
  linkedGre: number
  cityDescription: string
  entityDescription: string
}

export const readFileAsBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1])
      } else {
        reject(new Error('Failed to read file as Base64'))
      }
    }
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

export const FileAsBase64 = (file: File) => {
  const reader = new FileReader()

  reader.onload = () => {
    if (reader.result && typeof reader.result === 'string') {
      return reader.result.split(',')[1]
    } else {
      throw new Error('Failed to read file as Base64')
    }
  }

  reader.readAsDataURL(file)
}

export const AbrirSolicitacoes = () => {
  const currentRoute = window.location.pathname
  const parts = currentRoute.split('/')
  const id = parts[parts.length - 1]
  const navigate = useNavigate()

  const {userData, lotacaoSelecionada} = useAuth()

  const {data: customfields, isLoading} = useGetFieldsCustom({
    idServico: id,
  })

  const {data: escolasGre} = useGetEntidadeSolicitante({
    idGre: lotacaoSelecionada.codGre,
    isGRE: lotacaoSelecionada.isGre,
    enabled: Boolean(lotacaoSelecionada.codGre),
  })
  const issueSchema = generateSchema(customfields ?? [])

  const {handleSubmit, control, reset, resetField} = useForm<any>({
    resolver: zodResolver(issueSchema),
    mode: 'onSubmit',
  })

  const {AbrirSolicitacao, isLoadingSolicitacao} = UseAbrirSolicitacao({
    onSuccess: (data) => {
      toast.success(data)
      customfields?.map((item: any) => {
        return resetField(item.name, {keepError: true})
      })
      const str = data
      const number = str.match(/\d+/)
      if (number) {
        navigate(
          `/carta-de-servicos/minhas-solicitacoes/detalhes-da-solicitacao/${number[0]}`,
          {replace: true},
        )
      } else {
        toast.error('Redirecionar a pagina')
      }
      // resetField('File', {keepError: true})
      reset(defaultValues)
    },
  })

  const formPayload = ({
    description,
    subject,
    ...customFieldsData
  }: {
    description: string
    subject: string
    [key: string]: any // Allow any additional properties in customFieldsData
  }): Promise<{
    issue: {
      tracker_id: number
      subject: string
      description: string
      custom_fields: Array<{id: number; name: string; value: any}>
    }
  }> => {
    return new Promise((resolve, reject) => {
      if (customfields) {
        const customFieldsMap: Array<
          | {id: number; name: string; value: any}
          | Promise<{id: number; name: string; value: any} | null>
        > = customfields.map(async (item) => {
          if (item.fieldFormat === 'int') {
            return {
              id: item.id,
              name: item.name,
              value: Number(customFieldsData[item.name]),
            }
          }
          if (
            item.fieldFormat === 'attachment' &&
            customFieldsData[item.name]
          ) {
            const content_base64 = await readFileAsBase64(
              customFieldsData[item.name],
            )
            return {
              id: item.id,
              name: item.name,
              value: {
                filename: customFieldsData[item.name]?.name,
                content_type: customFieldsData[item.name]?.type,
                content_base64,
              },
            }
          }
          return {
            id: item.id,
            name: item.name,
            value: customFieldsData[item.name],
          }
        })

        Promise.all(customFieldsMap)
          .then((resolvedValues) => {
            const filteredData = resolvedValues?.filter(
              (item: any) => item.value !== undefined,
            )
            const payload: any = {
              issue: {
                tracker_id: Number(id),
                subject,
                description,
                custom_fields: filteredData,
              },
            }
            resolve(payload)
          })
          .catch((error) => {
            // Handle errors if any of the promises reject
            console.error(error)
            reject(new Error('Error while processing custom fields'))
          })
      } else {
        // Handle the case where customfields is undefined
        console.error('customfields is undefined')
        reject(new Error('customfields is undefined'))
      }
    })
  }

  async function onSubmit(data: any) {
    console.log(data)
    formPayload(data)
      .then((payload) => AbrirSolicitacao(payload))
      .catch((error) => console.error(error))
  }

  const handleCancelarSolicitacao = () => {
    navigate('/carta-de-servicos')
  }
  const userDataMappingUser: {[key: string]: keyof UserData} = {
    'Nome do solicitante': 'name',
    'E-mail do solicitante': 'email',
  }

  const userDataMappingLotacao: {[key: string]: keyof ILotacaoUser} = {
    'Matrícula do solicitante': 'matricula',
    'Lotação do solicitante': 'funcao',
    'Município da entidade': 'municipioEntidade',
    'Entidade solicitante': 'nomeEntidade',
    'GRE Responsável': 'codGre',
  }

  const showfieldsUser: {[key: string]: keyof UserData} = {
    'Município da entidade': 'cityDescription',
    'Entidade solicitante': 'entityDescription',
  }

  const showfieldsLotacao: {[key: string]: keyof ILotacaoUser} = {
    'Município da entidade': 'municipioEntidade',
    'Entidade solicitante': 'nomeEntidade',
  }

  const matchedTrackerName = customfields?.[0]?.trackers.map((item) =>
    String(item.id) === id ? item.name : null,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box>
        <Navbar />
        <Box className="fixed max-[540px]:h-[140px] max-[500px]:h-[160px] h-[120px] w-full bg-[white] z-[999] flex flex-col items-center  justify-center">
          <Box
            maxWidth={'xl'}
            className="w-full flex gap-[10px] flex-col z-[999] justify-center px-[20px]">
            <Breadcrumbs>
              <Link
                className="hover:underline"
                color="inherit"
                to="/carta-de-servicos">
                Carta de Serviços
              </Link>
              <p className="text-[#004394]" color="inherit">
                {matchedTrackerName}
              </p>
            </Breadcrumbs>
            <Divider></Divider>
            <p className="max-[800px]:text-[20px] text-[25px] font-[700]">
              Abrir Solicitação para {matchedTrackerName}
            </p>
            <Divider />
          </Box>
        </Box>
        <Container
          maxWidth="xl"
          className="mt-[120px] max-[540px]:mt-[140px] max-[500px]:mt-[160px]">
          <Box sx={{marginTop: 6}}>
            <form className="flex flex-col gap-[10px] border border-[#DFE4EA] p-[24px] rounded">
              {lotacaoSelecionada.id === undefined ? (
                <Typography className="text-red-600">
                  {' '}
                  Selecione o seu perfil!
                </Typography>
              ) : (
                ''
              )}
              <p className={'font-bold text-[24px]'}>Solicitação</p>
              <p className="font-bold mb-[20px]">Campos obrigatórios (*)</p>
              {Array.isArray(customfields) ? (
                customfields.map((item: any) => (
                  <React.Fragment key={item.id}>
                    {(userDataMappingUser[item.name] ||
                      userDataMappingLotacao[item.name]) &&
                    showfieldsLotacao[item.name] ? (
                      item.name === 'Entidade solicitante' &&
                      lotacaoSelecionada.isGre ? (
                        <FormInputDropdown
                          variant="outlined"
                          name={item.name}
                          control={control}
                          label={item.name + ' (*)'}
                          rules={{
                            required: item.required
                              ? 'Campo obrigatório'
                              : false,
                          }}
                          options={escolasGre?.escolas || []}
                        />
                      ) : (
                        <DisabledRHFTextField
                          key={item.id}
                          control={control}
                          name={item.name}
                          label={item.name}
                          value={
                            userDataMappingUser[item.name]
                              ? userData[userDataMappingUser[item.name]]
                              : lotacaoSelecionada[
                                  userDataMappingLotacao[item.name]
                                ]
                          }
                          valueDefault={
                            userDataMappingUser[item.name]
                              ? userData[userDataMappingUser[item.name]]
                              : lotacaoSelecionada[
                                  userDataMappingLotacao[item.name]
                                ]
                          }
                        />
                      )
                    ) : (
                      <div className="hidden">
                        <DisabledRHFTextField
                          key={item.id}
                          control={control}
                          name={item.name}
                          hidden={true}
                          label={item.name}
                          value={
                            userDataMappingUser[item.name]
                              ? userData[userDataMappingUser[item.name]]
                              : lotacaoSelecionada[
                                  userDataMappingLotacao[item.name]
                                ]
                          }
                          valueDefault={
                            userDataMappingUser[item.name]
                              ? userData[userDataMappingUser[item.name]]
                              : lotacaoSelecionada[
                                  userDataMappingLotacao[item.name]
                                ]
                          }
                        />
                      </div>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <div>No custom fields found.</div>
              )}
              <Box>
                <TextFieldRHF
                  name={'subject'}
                  control={control}
                  rules={{required: 'Campo obrigatório '}}
                  placeholder={'Informe a solicitação'}
                  label={'Assunto (*)'}></TextFieldRHF>
              </Box>
              <Box>
                <TextAreaTail
                  placeholder="Adicione um comentário na solicitação"
                  rules={{required: 'Campo obrigatório '}}
                  name={'description'}
                  control={control}
                  label={'Justificativa (*)'}></TextAreaTail>
              </Box>
            </form>
            {customfields?.some((item) => item.category !== 'SOLICITACAO') && (
              <form className="flex flex-col gap-[10px] my-5 border border-[#DFE4EA] p-[24px] rounded mt-[20px]">
                <p className="font-bold text-[24px]">
                  Dados Específico do Serviço
                </p>
                <p className="font-bold mb-[20px]">Campos obrigatórios (*)</p>
                {Array.isArray(customfields) ? (
                  customfields.map((item: any) => (
                    <React.Fragment key={item.id}>
                      {item.category !== 'SOLICITACAO' &&
                        item.category !== 'ACOMPANHAMENTO' && (
                          <div>
                            {item.fieldFormat === 'list' ? (
                              <FormInputDropdown
                                variant="outlined"
                                name={item.name}
                                control={control}
                                hidden
                                rules={{
                                  required: item.required
                                    ? 'Campo obrigatório'
                                    : false,
                                }}
                                label={item.name}
                                optionsLabel={item.possibleValues || []}
                              />
                            ) : item.fieldFormat === 'attachment' ? (
                              <Box className="flex flex-col">
                                <FormLabel>{String(item.name)}</FormLabel>
                                <FileInput
                                  type="file"
                                  name={String(item.name)}
                                  rules={{
                                    required: item.required
                                      ? 'Campo obrigatório'
                                      : false,
                                  }}
                                  multiple={false}
                                  control={control}
                                  nameButton={'Adicionar'}
                                />
                              </Box>
                            ) : item.fieldFormat === 'int' ? (
                              <Box className="flex flex-col gap-2">
                                <TextFieldRHF
                                  name={item.name}
                                  control={control}
                                  placeholder={item.name}
                                  label={item.name}></TextFieldRHF>
                              </Box>
                            ) : item.fieldFormat === 'date' ? (
                              <Box className="gap-2">
                                <TextFieldRHF
                                  sx={{width: '320px'}}
                                  name={item.name}
                                  type={item.fieldFormat}
                                  control={control}
                                  placeholder={item.name}
                                  label={item.name}
                                  rules={{
                                    required: item.required
                                      ? 'Campo obrigatório'
                                      : false,
                                    validate: (value: any) => {
                                      if (
                                        String(value).length > item.maxLength
                                      ) {
                                        return `Valor não pode utrapassar ${item.maxLength} caracteres`
                                      } else if (
                                        String(value).length < item.minLength
                                      ) {
                                        return `Valor não pode menor que ${item.minLength} caracteres`
                                      }
                                      return true
                                    },
                                  }}></TextFieldRHF>
                              </Box>
                            ) : (
                              <TextFieldRHF
                                name={item.name}
                                type={item.fieldFormat}
                                control={control}
                                placeholder={item.name}
                                label={item.name}
                                rules={{
                                  required: item.required
                                    ? 'Campo obrigatório'
                                    : false,
                                  validate: (value: any) => {
                                    if (String(value).length > item.maxLength) {
                                      return `Valor não pode utrapassar ${item.maxLength} caracteres`
                                    } else if (
                                      String(value).length < item.minLength
                                    ) {
                                      return `Valor não pode menor que ${item.minLength} caracteres`
                                    }
                                    return true
                                  },
                                }}></TextFieldRHF>
                            )}
                          </div>
                        )}
                    </React.Fragment>
                  ))
                ) : (
                  <div>No custom fields found.</div>
                )}
              </form>
            )}
            {customfields?.some(
              (item) => item.category === 'ACOMPANHAMENTO',
            ) && (
              <form className="flex flex-col gap-[10px] my-5 border border-[#DFE4EA] p-[24px] rounded mt-[20px]">
                <p className="font-bold text-[24px]">Dados de Acompanhamento</p>
                <p className="font-bold">Campos obrigatórios (*)</p>
              </form>
            )}
            <Box className="flex gap-2 mb-[200px] mt-[20px]">
              <LoadingButton
                loading={isLoadingSolicitacao}
                loadingPosition="start"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleSubmit(onSubmit)}>
                {isLoadingSolicitacao ? 'Carregando...' : 'Adicionar'}
              </LoadingButton>
              <Button
                startIcon={<ClearIcon />}
                onClick={handleCancelarSolicitacao}
                color="error"
                variant="contained">
                Cancelar
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default AbrirSolicitacoes
