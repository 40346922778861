import React, {ReactNode, useState, MouseEvent, useEffect} from 'react'

import {zodResolver} from '@hookform/resolvers/zod'
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Typography,
} from '@mui/material'
import BasicBreadcrumbs from 'components/atoms/bread/breadcrumbs'
import {FormInputDropdown} from 'components/atoms/dropdown/dropdownInput'
import {TextFieldRHF} from 'components/atoms/form/TextFieldRHF'
import Footer from 'components/molecules/footer/Footer'
import Navbar from 'components/molecules/navbar/Navbar'
import {format} from 'date-fns'
import {
  IGetMinhasSolicitacoesRequest,
  useGetServices,
} from 'domains/ServiceDomain'
import {useGetCategorias} from 'domains/ServiceDomain/useCases/useGetCategorias'
import {useGetEntidadeSolicitante} from 'domains/ServiceDomain/useCases/useGetEntidadeSolicitante'
import {useGetMinhasSolicitacoes} from 'domains/ServiceDomain/useCases/useMinhasSolicitacoes'
import {useForm} from 'react-hook-form'
import {
  AiOutlineFileDone,
  AiOutlineFileSearch,
  AiOutlineReload,
} from 'react-icons/ai'
import {BiSearch} from 'react-icons/bi'
import {BsThreeDotsVertical} from 'react-icons/bs'
import {FaArrowDown, FaArrowUp} from 'react-icons/fa'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from 'services/auth'

import styles from '../../../styles/table.module.css'
import {
  ISearchParameters,
  showStatus,
} from '../minhasSolicitacoes/minhasSolicitacoes'
import {issueMinhasSolicitacaoSchema} from '../minhasSolicitacoes/minhasSolicitacoesSchema'

export const formatDate = (dataRecebida: Date): string => {
  const dateObject = new Date(dataRecebida)

  const formattedDate = format(dateObject, 'dd/MM/yyyy')
  const formattedTime = format(dateObject, 'HH:mm')

  return `${formattedDate} | ${formattedTime}`
}

const ITEMS_PER_PAGE = 10

const ListaDeAprovacoes = () => {
  const {control, reset, handleSubmit} = useForm<ISearchParameters>({
    resolver: zodResolver(issueMinhasSolicitacaoSchema),
  })
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [id, setId] = useState(0)
  const [statusCod, setStatusCod] = useState(0)
  const {lotacaoSelecionada, status} = useAuth()
  const open = Boolean(anchorEl)
  const [filters, setFilters] = useState<IGetMinhasSolicitacoesRequest>({
    perfilId: lotacaoSelecionada.idFuncao,
    escola:
      !lotacaoSelecionada.isGre && lotacaoSelecionada.codInepEntidade
        ? lotacaoSelecionada.codInepEntidade?.toString()
        : '',
    id: '',
    servico: '',
    subject: '',
    dataAbertura: '',
    dataAtualizacao: '',
    statusId: '20',
    categoria: '',
    gre: lotacaoSelecionada.codGre ?? '',
  })
  const {data: results, isLoading} = useGetMinhasSolicitacoes({...filters})
  const {data: servicos} = useGetServices({})

  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = results ? Math.ceil(results.length / ITEMS_PER_PAGE) : 0
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE
  const currentItems = results?.slice(indexOfFirstItem, indexOfLastItem)

  const handleClick = (
    event: MouseEvent<HTMLElement>,
    id: number,
    statusCod: number,
  ) => {
    setAnchorEl(event.currentTarget)
    setId(id)
    setStatusCod(statusCod)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [sortBy, setSortBy] = useState<string | null>('updatedOn')
  const [sortDirection, setSortDirection] = useState('desc')

  const handleSort = (key: string) => {
    if (sortBy === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(key)
      setSortDirection('asc')
    }
  }

  const {data: escolasGre} = useGetEntidadeSolicitante({
    idGre: lotacaoSelecionada.codGre,
    isGRE: lotacaoSelecionada.isGre,
    enabled: Boolean(lotacaoSelecionada.codGre),
  })

  const servicoOptions = servicos
    ? servicos.map((result) => ({
        value: result.id.toString(),
        label: result.name,
      }))
    : []

  const {data: categorias} = useGetCategorias()

  const categoriaObj = categorias?.map((categoria) => ({
    label: categoria,
    value: categoria,
  }))

  const sortedData = currentItems
    ? [...currentItems].sort((a: any, b: any) => {
        if ((sortBy && sortBy === 'updatedOn') || sortBy === 'createdOn') {
          if (new Date(a[sortBy]) < new Date(b[sortBy]))
            return sortDirection === 'asc' ? -1 : 1
          if (new Date(a[sortBy]) > new Date(b[sortBy]))
            return sortDirection === 'asc' ? 1 : -1
          return 0
        } else if (sortBy && sortBy === 'tracker') {
          if (a[sortBy].name < b[sortBy].name)
            return sortDirection === 'asc' ? -1 : 1
          if (a[sortBy].name > b[sortBy].name)
            return sortDirection === 'asc' ? 1 : -1
          return 0
        } else if (sortBy && sortBy === 'subject') {
          if (a[sortBy].toUpperCase() < b[sortBy].toUpperCase())
            return sortDirection === 'asc' ? -1 : 1
          if (a[sortBy].toUpperCase() > b[sortBy].toUpperCase())
            return sortDirection === 'asc' ? 1 : -1
          return 0
        } else if (sortBy && sortBy === 'id') {
          if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1
          if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1
          return 0
        }
        return 0
      })
    : []

  const onSubmit = (data: ISearchParameters) => {
    const entidadeSolicitante = lotacaoSelecionada.isGre
      ? data?.entidadeSolicitante?.split(' - ')?.[2]
      : lotacaoSelecionada.codInepEntidade
    setFilters((prev) => ({
      ...prev,
      id: data.id ?? '',
      escola: entidadeSolicitante ?? '',
      categoria: data.categoria ?? '',
      servico: data.servico ?? '',
      subject: data.subject ?? '',
      dataAbertura: data.dataAbertura ?? '',
      dataAtualizacao: data.dataAtualizacao ?? '',
      statusId: data.status ?? '',
    }))
    setCurrentPage(1)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar />
      <Box className="w-full flex flex-col items-center mb-[180px]">
        <Box className="fixed h-[120px] w-full bg-[white] z-[999] flex flex-col items-center  justify-center">
          <Box
            maxWidth={'xl'}
            className="w-full flex gap-[10px] flex-col z-[999] justify-center px-[20px]">
            <Breadcrumbs>
              <Link
                className="hover:underline"
                color="inherit"
                to="/carta-de-servicos">
                Carta de Serviços
              </Link>
              <p className="text-[#004394]" color="inherit">
                Lista de Aprovações
              </p>
            </Breadcrumbs>
            <Divider />
            <p className="font-[700] text-[28px]">Lista de Aprovações</p>
            <Divider />
          </Box>
        </Box>
        <Box
          maxWidth={'xl'}
          className="w-full min-h-[100vh] mt-[85px] py-[40px] px-[20px]">
          <Box className="flex flex-col gap-[30px]">
            <Box className="w-full flex flex-col gap-[30px] rounded bg-[#F3F4F6] p-[40px]">
              <p className="font-bold">Buscar Solicitação</p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-[10px]">
                <Box className="flex flex-wrap gap-[10px]">
                  {lotacaoSelecionada.isGre ? (
                    <Box className="grow-[1] lg:max-w-[703px] min-w-[280px] min-[500px]:max-w-[100%]">
                      <FormInputDropdown
                        variant="outlined"
                        control={control}
                        label="Entidade Solicitante"
                        name="entidadeSolicitante"
                        className="bg-[white]"
                        options={escolasGre?.escolas || []}
                      />
                    </Box>
                  ) : null}
                  <Box
                    className={
                      'grow-[1] lg:max-w-[703px] min-w-[280px] min-[500px]:max-w-[100%]'
                    }>
                    <FormInputDropdown
                      variant="outlined"
                      control={control}
                      label="Categoria"
                      name="categoria"
                      className="bg-[white]"
                      optionsLabel={categoriaObj || []}
                    />
                  </Box>
                </Box>
                <Box className="flex flex-wrap gap-[10px]">
                  <Box className="grow-[1] min-[675px]:max-w-[300px]">
                    <TextFieldRHF
                      label={'Código da Solicitação'}
                      control={control}
                      name="id"
                      placeholder="Buscar por código da solicitação"
                    />
                  </Box>
                  <Box className="grow-[1] min-w-[280px]">
                    <FormInputDropdown
                      variant="outlined"
                      control={control}
                      label="Serviço"
                      name="servico"
                      className="bg-[white]"
                      optionsLabel={servicoOptions}
                    />
                  </Box>
                </Box>
                <TextFieldRHF
                  label={'Assunto'}
                  control={control}
                  name="subject"
                  placeholder="Buscar por título da solicitação"
                />
                <Box className="flex flex-wrap gap-[10px]">
                  <Box className="grow-[1] lg:max-w-[703px] min-w-[280px] min-[500px]:max-w-[100%]">
                    <TextFieldRHF
                      label={'Data Inicial da Abertura da Solicitação'}
                      control={control}
                      type="date"
                      name="dataAbertura"
                      placeholder=""
                    />
                  </Box>
                  <Box className="grow-[1] lg:max-w-[703px] min-w-[280px] min-[500px]:max-w-[100%]">
                    <TextFieldRHF
                      label={'Data Final da Abertura da Solicitação'}
                      control={control}
                      type="date"
                      name="dataAtualizacao"
                      placeholder=""
                    />
                  </Box>
                </Box>
                <Box className="flex gap-[10px] mt-[20px]">
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{textTransform: 'none'}}
                    className="flex item-center gap-[5px] max-w-[120px]">
                    <BiSearch size={20} />
                    <p className="font-[500] text-[15px]">Buscar</p>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{textTransform: 'none'}}
                    color="error"
                    className="flex item-center gap-[5px]"
                    onClick={() =>
                      reset({
                        id: '',
                        entidadeSolicitante: '',
                        servico: '',
                        subject: '',
                        dataAbertura: '',
                        categoria: '',
                        dataAtualizacao: '',
                        status: '20',
                      })
                    }>
                    <AiOutlineReload size={20} />
                    <p className="font-[500] text-[15px]">Limpar Filros</p>
                  </Button>
                </Box>
              </form>
            </Box>

            <Box className="overflow-auto">
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th
                      className={'hover:cursor-pointer w-[160px]'}
                      onClick={() => handleSort('id')}>
                      <Box className="flex items-center justify-between">
                        <p>Código</p>
                        {sortBy === 'id' && sortDirection === 'asc' ? (
                          <FaArrowUp />
                        ) : (
                          <FaArrowDown />
                        )}
                      </Box>
                    </th>
                    <th
                      className={'hover:cursor-pointer w-[300px]'}
                      onClick={() => handleSort('tracker')}>
                      <Box className="flex items-center justify-between">
                        <p>Serviço</p>
                        {sortBy === 'tracker' && sortDirection === 'asc' ? (
                          <FaArrowUp />
                        ) : (
                          <FaArrowDown />
                        )}
                      </Box>
                    </th>
                    <th
                      className={'hover:cursor-pointer w-[300px]'}
                      onClick={() => handleSort('subject')}>
                      <Box className="flex items-center justify-between">
                        <p>Assunto</p>
                        {sortBy === 'subject' && sortDirection === 'asc' ? (
                          <FaArrowUp />
                        ) : (
                          <FaArrowDown />
                        )}
                      </Box>
                    </th>
                    <th
                      className={'hover:cursor-pointer w-[250px]'}
                      onClick={() => handleSort('createdOn')}>
                      <Box className="flex items-center justify-between">
                        <p className="max-w-[100px] text-left">
                          Abertura da Solicitação
                        </p>
                        {sortBy === 'createdOn' && sortDirection === 'asc' ? (
                          <FaArrowUp />
                        ) : (
                          <FaArrowDown />
                        )}
                      </Box>
                    </th>
                    <th
                      className={'hover:cursor-pointer w-[250px]'}
                      onClick={() => handleSort('updatedOn')}>
                      <Box className="flex items-center justify-between">
                        <p className="max-w-[100px] text-left">
                          Última Atualização
                        </p>
                        {sortBy === 'updatedOn' && sortDirection === 'asc' ? (
                          <FaArrowUp />
                        ) : (
                          <FaArrowDown />
                        )}
                      </Box>
                    </th>
                    <th className="w-[300px]">
                      <Box className="flex items-center justify-between">
                        <p>Status</p>
                      </Box>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody className="w-full text-center">
                    <tr>
                      <td colSpan={7} className="text-center">
                        Carregando...
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {sortedData.length === 0 ? (
                      <tr key="">
                        <td colSpan={7} className="text-center">
                          Nenhum resultado encontrado
                        </td>
                      </tr>
                    ) : (
                      sortedData.map((solicitacao, index) => (
                        <tr key={index}>
                          <td className="w-[160px]">{solicitacao.id}</td>
                          <td className="w-[300px]">
                            {solicitacao.tracker.name}
                          </td>
                          <td className="w-[300px]">{solicitacao.subject}</td>
                          <td className="w-[250px]">
                            {formatDate(solicitacao.createdOn)}
                          </td>
                          <td className="w-[250px]">
                            {formatDate(solicitacao.updatedOn)}
                          </td>
                          <td className="w-[300px] text-center">
                            {showStatus(solicitacao.status.id)}
                          </td>
                          <td>
                            <IconButton
                              sx={{
                                border: '1px solid #E5E7EB',
                                color: 'black',
                                borderRadius: 8,
                              }}
                              size="medium"
                              onClick={(event) =>
                                handleClick(
                                  event,
                                  solicitacao.id,
                                  solicitacao.status.id,
                                )
                              }>
                              <BsThreeDotsVertical size={20} />
                            </IconButton>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                )}
              </table>
            </Box>
            {sortedData.length !== 0 ? (
              <Box className="flex justify-center">
                <Box className="mt-[40px] max-w-[400px] p-[15px] rounded shadow-md">
                  <Pagination
                    count={totalPages}
                    color="primary"
                    onChange={(e, page) => setCurrentPage(page)}
                    page={currentPage}
                  />
                </Box>
              </Box>
            ) : null}
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              disableScrollLock={true}>
              <MenuItem
                onClick={() =>
                  navigate(
                    `/carta-de-servicos/minhas-solicitacoes/detalhes-da-solicitacao/${id}`,
                  )
                }>
                <Box className="flex items-center gap-[10px]">
                  <AiOutlineFileSearch size={24} />
                  <p>Detalhes da Solicitação</p>
                </Box>
              </MenuItem>
              {lotacaoSelecionada.isGre && statusCod === 20 ? (
                <MenuItem
                  onClick={() =>
                    navigate(
                      `/carta-de-servicos/minhas-solicitacoes/aprovacao-da-solicitacao/${id}`,
                    )
                  }>
                  <Box className="flex items-center gap-[10px]">
                    <AiOutlineFileDone size={24} />
                    <p>Aprovação da Solicitação</p>
                  </Box>
                </MenuItem>
              ) : null}
            </Menu>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

export default ListaDeAprovacoes
