import {MutationOptions} from 'infra'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

import {authService} from '../authService'
import {ISignInCredentials, ISignResponse} from '../authTypes'

export function useAuthSignIn(options?: MutationOptions<ISignResponse>) {
  const mutation = useMutation<ISignResponse, Error, ISignInCredentials>({
    mutationFn: async ({email, password}) => {
      return await authService.signIn({
        email,
        password,
      })
    },
    retry: false,
    onError: (error: any) => {
      toast.error(error.message.response.data ?? 'Credenciais inválidas')
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (userLogged) => {
      if (options?.onSuccess) {
        options.onSuccess(userLogged)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    signIn: (variables: ISignInCredentials) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
