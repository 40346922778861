import {ChangeEvent, useRef, useState} from 'react'

import {Box, Button} from '@mui/material'
import {UseFormClearErrors, UseFormSetValue} from 'react-hook-form'

interface IFileField {
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  error: any
}

const FileField = ({error, setValue, clearErrors}: IFileField) => {
  const [selectedFile, setSelectedFile] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: any) => {
    const file = e.target.files ? e.target.files[0]?.name : ''
    setValue('file', e.target.files?.[0])
    clearErrors('file')
    setSelectedFile(file)
  }

  const handleRemoveAnexo = () => {
    setSelectedFile('')
    setValue('file', undefined)

    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  return (
    <Box className="flex w-full flex-wrap gap-[10px]">
      <div className={'relative overflow-hidden z-[999] grow-[1]'}>
        <Box className="flex flex-col gap-[5px]">
          <input
            onChange={handleFileChange}
            type="file"
            ref={fileInputRef}
            multiple={false}
            className={
              'top-0 left-0 absolute items-center opacity-0 text-[0px] cursor-pointer display-none w-full h-[58px]'
            }
          />
          <div
            className={
              error
                ? 'flex items-center p-[10px] outline-[#E10E0E] flex-wrap gap-[10px] min-h-[58px] rounded-[6px] border-[1px] border-[#E10E0E] bg-[#fff]'
                : 'flex items-center p-[10px] outline-[#E1E8FF] flex-wrap gap-[10px] min-h-[58px] rounded-[6px] border-[1px] border-[#DFE4EA] bg-[#fff]'
            }>
            <label
              className={
                'min-h-[34px] min-w-[190px] px-[24px] flex flex-wrap justify-center rounded-[6px] items-center bg-[#E3E3E3]'
              }>
              <div className="text-[#000000] ">Anexar arquivo</div>
            </label>
            <span className={'overflow-hidden text-ellipsis text-[black]'}>
              {selectedFile ? (
                <p className="text-black">{selectedFile}</p>
              ) : (
                <p className="text-[#9CA3AF]">Nenhum arquivo selecionado</p>
              )}
            </span>
          </div>
          {error ? (
            <ul className="list-disc">
              {error.message && (
                <li className="text-[12px] text-[#E10E0E]">{error.message}</li>
              )}
            </ul>
          ) : null}
        </Box>
      </div>
      {selectedFile ? (
        <Button onClick={handleRemoveAnexo} color="error">
          Remover Anexo
        </Button>
      ) : null}
    </Box>
  )
}

export default FileField
