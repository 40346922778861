import React, {useState} from 'react'

import {Box, FormControl, FormLabel} from '@mui/material'
import {Controller, UseControllerProps} from 'react-hook-form'

interface ITextArea {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any
  placeholder: string
  label: string
  setValue?: unknown
  maxLength?: number
}

export type TextAreaFieldProps = ITextArea &
  UseControllerProps &
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >

export const TextAreaTail: React.FC<TextAreaFieldProps> = ({
  name,
  control,
  label,
  placeholder,
  rules,
  maxLength = 1000,
  ...propsTextArea
}) => {
  const [charCount, setCharCount] = useState<Number>(0)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const count = e.target.value.length
    setCharCount(count)
  }
  return (
    <div className="mb-4">
      <FormControl fullWidth size={'small'}>
        <FormLabel htmlFor={label}>{label}</FormLabel>
        <Controller
          rules={rules}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <>
              <textarea
                {...propsTextArea}
                id="message"
                rows={4}
                onChange={(e) => {
                  handleChange(e)
                  onChange(e)
                }}
                maxLength={maxLength}
                value={value}
                className={`block p-[20px] w-full bg-white border-gray-300 outline-[#ADBCF2] appearance-none border rounded-[6px] text-gray-700 leading-tight ${
                  error ? 'border-red-500' : ''
                }`}
                placeholder={placeholder}
              />
              <Box className="flex justify-between">
                <p className="flex justify-end items-end mt-2 text-xs text-red-500">
                  {error?.message}
                </p>
                <p className="flex justify-end items-end mt-2 text-xs text-gray-500">{`${charCount}/${maxLength}`}</p>
              </Box>
            </>
          )}
          control={control}
          name={name}
        />
      </FormControl>
    </div>
  )
}
