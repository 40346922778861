export const IconLock = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.40001 5.66425V5.05473C2.40001 3.02878 4.00573 1.39758 6.00001 1.39758C7.24229 1.39758 8.3338 2.03054 8.98033 2.99758M2.40001 5.66425C1.74001 5.66425 1.20001 6.21282 1.20001 6.8833V12.9785C1.20001 13.649 1.74001 14.1976 2.40001 14.1976H9.60001C10.26 14.1976 10.8 13.649 10.8 12.9785V6.8833C10.8 6.21282 10.26 5.66425 9.60001 5.66425H2.40001ZM6.00001 8.71187C6.66001 8.71187 7.20001 9.26044 7.20001 9.93092C7.20001 10.6014 6.66001 11.15 6.00001 11.15C5.34001 11.15 4.80001 10.6014 4.80001 9.93092C4.80001 9.26044 5.34001 8.71187 6.00001 8.71187Z"
        stroke="#111928"
        strokeLinecap="round"
      />
    </svg>
  )
}
