import {MutationOptions} from 'infra'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

import {serviceDomainService} from '../serviceDomainService'
import {IssueDataSolicitacao} from '../serviceDomainTypes'

export function UseAbrirSolicitacao(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, IssueDataSolicitacao>({
    mutationFn: async (dataPost: IssueDataSolicitacao) => {
      return await serviceDomainService.AbrirSolicitacao(dataPost)
    },
    retry: false,
    onError: (error: any) => {
      console.log(error)
      toast.error(
        error.message.response.data ??
          'Falha ao registrar uma nova solicitação',
      )
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data: any) => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoadingSolicitacao: mutation.isLoading,
    AbrirSolicitacao: (variables: IssueDataSolicitacao) =>
      mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
