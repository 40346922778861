import {Box, Divider, Drawer, Typography} from '@mui/material'
import {useGetPendenciasListaDeAprovacao} from 'domains/ServiceDomain/useCases/useGetPendenciasListaDeAprovacao'
import useGetFirstLetter from 'hooks/useGetFirstLetter'
import {BsPerson, BsSend} from 'react-icons/bs'
import {FaCheck} from 'react-icons/fa'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMail} from 'react-icons/md'
import {TbLogout2} from 'react-icons/tb'
import {Link} from 'react-router-dom'
import {useAuth} from 'services/auth'

import {IconChange} from './MenuComponent'

interface IDrawer {
  open: boolean
  toggleDrawer: (newOpen: boolean) => void
}

const DrawerComponent = ({open, toggleDrawer}: IDrawer) => {
  const {getFirstLetter} = useGetFirstLetter()
  const {onSignOut, userData, handleOpenModal, lotacaoSelecionada} = useAuth()
  const {data: pendencias} = useGetPendenciasListaDeAprovacao({
    idGre: lotacaoSelecionada.codGre ?? '00000',
  })

  const DrawerList = (
    <Box
      sx={{width: 250}}
      role="presentation"
      onClick={() => toggleDrawer(false)}>
      <Box className="h-[130px] w-full">
        <div className="absolute bg-[url('/public/assets/background-image.jpg')] bg-cover h-[130px] w-full" />
        <div className="absolute bg-[rgba(0,67,148,0.6)] h-[130px] w-full" />
        <div className="relative flex items-center h-[130px] justify-center">
          <img
            src="/assets/logo-governo-branco.svg"
            width={220}
            height={59}
            alt=""
          />
        </div>
      </Box>
      <Box className="flex flex-col gap-[10px] py-[10px]">
        <Box
          onClick={() => toggleDrawer(false)}
          className="hover:cursor-pointer bg-slate-200 hover:bg-slate-300">
          <Box className="flex px-[10px] py-[10px] gap-[10px] items-center">
            <Box className="bg-[#004394] rounded-[50px] p-[12px] font-bold text-white text-[12px]">
              {getFirstLetter(userData.name)}
            </Box>
            <div className="flex flex-col">
              <p className="text-[13px] text-black font-bold">
                {userData?.name?.toUpperCase()}
              </p>
              <p className="text-[10px] text-black ">
                {lotacaoSelecionada?.nomeEntidade} -{' '}
                {lotacaoSelecionada?.funcao}
              </p>
            </div>
          </Box>
        </Box>
        <Divider />
        <Box className="flex flex-col justify-center gap-[10px]">
          <Box
            onClick={handleOpenModal}
            className="flex items-center hover:bg-slate-200 hover:cursor-pointer py-[10px] gap-[10px] px-[20px]">
            <IconChange color="#004394" />
            <p>Trocar de GRE/Escola</p>
          </Box>
          <Box className="flex items-center gap-[10px] px-[15px] py-[10px] hover:bg-slate-200 hover:cursor-pointer">
            <GoGlobe size={25} color="#004394" />
            <Link to={'/carta-de-servicos'}>Portal Seduc-PI</Link>
          </Box>
          <Box className="flex items-center gap-[10px] px-[15px] py-[10px] hover:bg-slate-200 hover:cursor-pointer">
            <BsSend size={25} color="#004394" />
            <Link to={'/carta-de-servicos/minhas-solicitacoes'}>
              Minhas Solicitações
            </Link>
          </Box>
          {lotacaoSelecionada.isGre ? (
            <Box className="flex items-center gap-[10px] px-[15px] py-[10px] hover:bg-slate-200 hover:cursor-pointer">
              <FaCheck size={25} color="#004394" />
              <Link
                to={'/carta-de-servicos/lista-de-aprovacoes'}
                className="flex w-[200px]">
                <p>Lista de Aprovações</p>
                {pendencias ? (
                  <Box className="ml-[-5px] mt-[-5px] rounded-[50px] w-[20px] h-[20px] text-[12px] flex items-center justify-center font-bold text-white bg-[red]">
                    {pendencias}
                  </Box>
                ) : null}
              </Link>
            </Box>
          ) : null}
        </Box>
        <Divider />
        <Box className="flex flex-col justify-center gap-[10px]">
          <Box
            className="flex items-center gap-[10px] px-[15px] py-[10px] hover:bg-slate-200 hover:cursor-pointer"
            onClick={onSignOut}>
            <TbLogout2 size={25} color="#004394" />
            <Link to={'#'}>Sair</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Drawer
      open={open}
      onClose={() => toggleDrawer(false)}
      disableScrollLock={true}>
      {DrawerList}
    </Drawer>
  )
}

export default DrawerComponent
