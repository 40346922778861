import {AxiosError} from 'axios'

import {authApi} from './authApi'
import {ISignInCredentials, ISignResponse} from './authTypes'

async function signIn(data: ISignInCredentials): Promise<ISignResponse> {
  try {
    return await authApi.signIn(data)
  } catch (error: any) {
    throw new AxiosError(error)
  }
}

export const authService = {
  signIn,
}
