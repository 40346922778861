import {Dispatch, MouseEvent, SetStateAction, useState} from 'react'

import {Box, IconButton, Typography, useMediaQuery} from '@mui/material'
import DrawerComponent from 'components/DrawerComponent'
import MenuComponent from 'components/MenuComponent'
import {useGetPendenciasListaDeAprovacao} from 'domains/ServiceDomain/useCases/useGetPendenciasListaDeAprovacao'
import useGetFirstLetter from 'hooks/useGetFirstLetter'
import {FiBell, FiMenu} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {useAuth} from 'services/auth'
const Navbar = () => {
  const {userData, lotacaoSelecionada} = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const {data: pendencias} = useGetPendenciasListaDeAprovacao({
    idGre: lotacaoSelecionada.codGre ?? '00000',
  })

  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = (newOpen: boolean) => {
    setOpenDrawer(newOpen)
  }

  const {getFirstLetter} = useGetFirstLetter()

  return (
    <div className="min-h-[80px]">
      <Box
        className="flex items-center w-full justify-between py-[20px] sm:px-[40px] h-[80px] gap-[10px] shadow-md  max-sm:px-[25px] bg-[#004394]"
        style={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100}}>
        <Box className="flex items-center gap-[80px] text-white font-[200] text-[14px]">
          <Link to={'/carta-de-servicos'}>
            <img
              src="/assets/logo-governo-branco.svg"
              width={250}
              height={63}
              alt="Logo do Governo do Piaui"
            />
          </Link>
          <Box
            className={
              !lotacaoSelecionada.isGre
                ? 'flex gap-[40px] max-[1250px]:hidden'
                : 'flex gap-[40px] max-[1390px]:hidden'
            }>
            <Link to={'/carta-de-servicos'}>CARTA DE SERVIÇOS</Link>
            <Link to={'/carta-de-servicos/minhas-solicitacoes'}>
              MINHAS SOLICITAÇÕES
            </Link>
            {lotacaoSelecionada.isGre ? (
              <Link
                to={'/carta-de-servicos/lista-de-aprovacoes'}
                className="flex w-[200px]">
                <p>LISTA DE APROVAÇÕES</p>
                {pendencias && pendencias !== 0 ? (
                  <Box className="ml-[-5px] mt-[-5px] rounded-[50px] w-[20px] h-[20px] text-[12px] flex items-center justify-center font-bold text-white bg-[red]">
                    {pendencias}
                  </Box>
                ) : null}
              </Link>
            ) : null}
          </Box>
        </Box>
        <Box
          className={
            !lotacaoSelecionada.isGre
              ? 'min-[1251px]:hidden'
              : 'min-[1391px]:hidden'
          }>
          <IconButton onClick={() => toggleDrawer(true)}>
            <FiMenu color="white" size={35} />
          </IconButton>
        </Box>

        <Box
          className={
            !lotacaoSelecionada.isGre
              ? 'flex justify-end items-center max-[1250px]:hidden cursor-pointer'
              : 'flex justify-end items-center max-[1390px]:hidden cursor-pointer'
          }
          onClick={handleClick}>
          <Box className="flex items-center gap-[20px] ">
            {/* <FiBell size={32} color="white" /> */}
            <IconButton>
              <Box className="bg-[#FCB900] rounded-[50px] p-[15px] font-bold text-white text-[18px]">
                {getFirstLetter(userData.name)}
              </Box>
            </IconButton>
          </Box>

          <Box className="break-words max-w-[400px]">
            <p className="text-white font-[900] text-[13px]">
              {userData?.name.toUpperCase()}
            </p>
            <p className="text-[#f9f9f9] text-[10px] max-w-[300px]">
              {lotacaoSelecionada?.nomeEntidade} - {lotacaoSelecionada?.funcao}
            </p>
          </Box>
        </Box>
        <DrawerComponent open={openDrawer} toggleDrawer={toggleDrawer} />
        <MenuComponent
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
        />
      </Box>
      <div></div>
    </div>
  )
}

export default Navbar
