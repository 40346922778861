import {Login} from '../pages/login/login'

export const authRoutes = [
  {
    path: '/',
    template: <Login />,
    subRoutes: [
      {
        path: '',
        component: () => <Login />,
        isPrivate: true,
      },
    ],
  },
]
