import React, {useEffect, useState} from 'react'

import {zodResolver} from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormLabel,
  Typography,
} from '@mui/material'
import BasicBreadcrumbs from 'components/atoms/bread/breadcrumbs'
import CustomButton from 'components/atoms/Button/ButtonComponent'
import DisabledSelectField from 'components/atoms/disabledSelectField/DisabledSelectField'
import DisabledTextArea from 'components/atoms/disabledTextArea/DisabledTextArea'
import DisabledTextField from 'components/atoms/disabledTextField/DisabledTextField'
import {FormInputDropdown} from 'components/atoms/dropdown/dropdownInput'
import FileInputDownload from 'components/atoms/fileDownload/fieDownload'
import ModalAproved from 'components/atoms/modal/ModalAproved'
import {TextAreaTail} from 'components/atoms/textarea/textarea'
import FileInput from 'components/atoms/upload/upload'
import Navbar from 'components/molecules/navbar/Navbar'
import {IGetFileResponse} from 'domains/ServiceDomain'
import {UseAprovarSolicitacao} from 'domains/ServiceDomain/useCases/useAprovarSolicitacao'
import {useGetFileDownload} from 'domains/ServiceDomain/useCases/useFileDownload'
import {useGetFieldsCustom} from 'domains/ServiceDomain/useCases/useGetFieldsCustom'
import {useGetFile} from 'domains/ServiceDomain/useCases/useGetFile'
import {useGetSolicitacao} from 'domains/ServiceDomain/useCases/useGetSolicitacao'
import {useForm} from 'react-hook-form'
import {BsDownload} from 'react-icons/bs'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from 'services/auth'

import {formatDate} from '../minhasSolicitacoes'

import {
  AceitarSolicitacaoSchema,
  aprovarSchema,
} from './aprovarSolicitacoesShema'

interface IFormInput {
  textValue: string
  radioValue: string
  checkboxValue: string[]
  dateValue: Date
  dropdownValue: string
  sliderValue: number
}

interface UserData {
  registrationNumber: string
  name: string
  profileDescription: string
  email: string
  cityDescription: string
  entityDescription: string
}

const defaultValues = {
  idSolicitacao: '13739',
}

const options = [
  {
    label: 'APROVADO',
    value: 'APROVAR',
  },
  {
    label: 'REPROVADO',
    value: 'REJEITAR',
  },
]

export const AprovarSolicitacoes = () => {
  const {handleSubmit, control, watch} = useForm<any>({
    // resolver: zodResolver(aprovarSchema),
  })

  const navigate = useNavigate()

  const currentRoute = window.location.pathname
  const parts = currentRoute.split('/')
  const id = parts[parts.length - 1]

  const [open, setOpen] = useState(false)

  const selectedOption = watch('acao')

  const {userData, lotacaoSelecionada} = useAuth()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancelModal = () => {
    setOpen(false)
  }

  const formPayload = ({acao, nota}: {acao: string; nota: string}) => {
    const payload = {
      idSolicitacao: id,
      acao,
      nota,
    }
    return payload
  }

  const {AprovarSolicitacao} = UseAprovarSolicitacao({
    onSuccess: (data) => {
      toast.success(data)
      setOpen(false)
      navigate('/carta-de-servicos/minhas-solicitacoes')
    },
  })

  const {data: solicitacao, isSuccess} = useGetSolicitacao(id)
  const anexo =
    solicitacao?.customFields?.filter((field) => field.id === 141) ?? []

  function onSubmit(data: any) {
    AprovarSolicitacao(formPayload(data))
  }

  const servicoFields = solicitacao?.customFields?.filter(
    (field) => field.category === 'SERVICO',
  )

  const acompanhaFields = solicitacao?.customFields?.filter(
    (field) => field.category === 'ACOMPANHAMENTO',
  )

  const {data: customfields, isLoading} = useGetFieldsCustom({
    idServico: String(solicitacao?.tracker.id),
    enabled: isSuccess,
  })

  const {getFile} = useGetFile({
    onSuccess: (data: IGetFileResponse) => {
      const linkSource = `data:${data.contentType};base64,${data.content}`
      const downloadLink = document.createElement('a')
      const fileName = data.fileName

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box>
        <Navbar />
        <Box className="fixed max-[500px]:h-[160px] h-[120px] w-full bg-[white] z-[999] flex flex-col items-center  justify-center">
          <Box
            maxWidth={'xl'}
            className="w-full flex gap-[10px] flex-col z-[999] justify-center px-[20px]">
            <Breadcrumbs>
              <Link
                className="hover:underline"
                color="inherit"
                to="/carta-de-servicos">
                Carta de Serviços
              </Link>
              <Link
                className="hover:underline"
                color="inherit"
                to="/carta-de-servicos/minhas-solicitacoes">
                Minhas Solicitações
              </Link>
              <p className="text-[#004394]" color="inherit">
                Aprovação da Solicitação
              </p>
            </Breadcrumbs>
            <Divider></Divider>
            <p className="text-[28px] font-[700]">Aprovar Solicitação</p>
            <Divider />
          </Box>
        </Box>
        <Container maxWidth="xl" className="mt-[120px] max-[500px]:mt-[160px]">
          <Box>
            <form className="flex flex-col gap-4 my-5 border border-[#DFE4EA] p-[24px] rounded mt-[48px]">
              <Typography sx={{fontWeight: 600, marginBottom: 2, fontSize: 28}}>
                Solicitação
              </Typography>
              <DisabledTextField
                value={solicitacao?.subject}
                label={'Assunto'}></DisabledTextField>
              <Box className="grid md:grid-cols-3 gap-3">
                <DisabledTextField
                  value={String(solicitacao?.id)}
                  label={'Nº da Solicitação'}></DisabledTextField>
                <DisabledTextField
                  value={formatDate(solicitacao?.createdOn ?? new Date())}
                  label={'Data e Hora da Criação'}></DisabledTextField>
                <DisabledTextField
                  value={formatDate(solicitacao?.updatedOn ?? new Date())}
                  label={'Data e Hora da Atualização'}></DisabledTextField>
                <DisabledTextField
                  value={String(solicitacao?.customFields[4]?.value)}
                  label={'Entidade Solicitante'}></DisabledTextField>
                <DisabledTextField
                  value={String(solicitacao?.customFields[1]?.value)}
                  label={'Usuário Solicitante'}></DisabledTextField>
                <DisabledTextField
                  value={String(solicitacao?.telefoneSolicitante)}
                  label={'Telefone'}></DisabledTextField>
              </Box>
              <Box className="grid md:grid-cols-2 gap-3">
                <DisabledTextField
                  value={String(solicitacao?.customFields[2]?.value)}
                  label={'Lotação do Solicitante'}></DisabledTextField>
                <DisabledTextField
                  value={String(solicitacao?.customFields[3]?.value)}
                  label={'E-mail do Solicitante'}></DisabledTextField>
              </Box>
              <Box>
                <DisabledTextArea
                  value={solicitacao?.description}
                  label={'Justificativa'}></DisabledTextArea>
              </Box>
              {solicitacao &&
              Array.isArray(solicitacao.attachments) &&
              solicitacao.attachments.length > 0 ? (
                <>
                  <Box className="flex flex-col gap-1">
                    <FormLabel>Anexos</FormLabel>
                    <Typography>
                      Tipos de arquivos aceitos: pdf, png, jpg, tiff | Tamanho
                      máximo do arquivo: 2M
                    </Typography>
                  </Box>
                  <Box>
                    <FileInputDownload
                      value={solicitacao.attachments[0]?.fileName}
                      handleButton={() =>
                        getFile({id: Number(solicitacao.attachments[0]?.id)})
                      }
                      nameButton={'Download'}
                    />
                  </Box>
                </>
              ) : null}
            </form>
            <form className="flex flex-col gap-4 my-5 border border-[#DFE4EA] p-[24px] rounded mt-[20px]">
              <Typography sx={{fontWeight: 600, marginBottom: 2, fontSize: 28}}>
                Dados de Específico do Serviço
              </Typography>
              {servicoFields?.map((item) =>
                customfields?.some(
                  (custom) =>
                    custom.fieldFormat === 'attachment' &&
                    custom.id === item.id,
                ) ? (
                  item.value !== '' ? (
                    <React.Fragment key={item.id}>
                      <FormLabel>{item.name}</FormLabel>
                      <ul className="list-item gap-[10px] list-disc ml-[40px]">
                        <li
                          key={item.id}
                          className="hover:text-[#004394] hover:cursor-pointer mb-[10px]"
                          onClick={() => getFile({id: Number(item.value)})}>
                          <Box className="flex gap-[10px] items-center">
                            Baixar Anexo <BsDownload />
                          </Box>
                        </li>
                      </ul>
                    </React.Fragment>
                  ) : null
                ) : (
                  <DisabledTextField
                    key={item.id}
                    value={String(item.value)}
                    label={item.name}
                  />
                ),
              )}
            </form>
            <form className="flex flex-col gap-4 my-5 border border-[#DFE4EA] p-[24px] rounded mt-[20px]">
              <Typography sx={{fontWeight: 600, marginBottom: 2, fontSize: 28}}>
                Dados de Acompanhamento
              </Typography>
              {acompanhaFields?.map((item) => (
                <DisabledTextField
                  key={item.id}
                  value={String(item.value)}
                  label={item.name}
                />
              ))}
            </form>
            {Boolean(lotacaoSelecionada.isGre) === true ? (
              <>
                <form>
                  <Box sx={{mt: 3}}>
                    <div className="bg-[#E1E8FF] rounded p-[28px]">
                      <Box className="md:w-[320px]">
                        <FormInputDropdown
                          variant="outlined"
                          name={'acao'}
                          control={control}
                          sx={{background: 'white'}}
                          rules={{required: 'Campo obrigatório '}}
                          label={'Parecer (APROVADO/REPROVADO)'}
                          optionsLabel={options}
                          // onChange={(value) => setSelectedOption(String(value))}
                        />
                      </Box>
                      <Box sx={{mt: 3}}>
                        <TextAreaTail
                          name={'nota'}
                          rules={{required: 'Campo obrigatório '}}
                          control={control}
                          label={'Comentário'}
                          placeholder="Informe um comentário aqui"></TextAreaTail>
                      </Box>
                    </div>
                  </Box>
                </form>
                <Box className="flex gap-2 mb-[200px] mt-[20px]">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleSubmit(handleOpen)}
                    variant="contained">
                    Salvar
                  </Button>
                  <ModalAproved
                    open={open}
                    handleClose={handleClose}
                    title="Aprovação de Solicitação"
                    content={`Deseja realmente ${selectedOption} a solicitacao`}
                    bold={`NÚMERO: ${id}`}
                    handleSubmitModal={handleSubmit(onSubmit)}
                    handleCancelModal={handleCancelModal}
                  />
                  <Button
                    startIcon={<ClearIcon />}
                    onClick={() =>
                      navigate('/carta-de-servicos/minhas-solicitacoes')
                    }
                    color="error"
                    variant="contained">
                    Cancelar
                  </Button>
                </Box>
              </>
            ) : (
              ''
            )}
          </Box>
        </Container>
      </Box>
    </>
  )
}
