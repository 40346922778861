export const IconArrowRight = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.83333 1.42261L11 5.79761M11 5.79761L6.83333 10.1726M11 5.79761L1 5.79761"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
