import {Box, Menu, Typography} from '@mui/material'
import useGetFirstLetter from 'hooks/useGetFirstLetter'
import {MdChangeCircle} from 'react-icons/md'
import {TbLogout2} from 'react-icons/tb'
import {useAuth} from 'services/auth'

interface IMenu {
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
}

export const IconChange = ({color}: {color: string}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.81654 20.6L1.40039 17M1.40039 17L4.81654 13.4M1.40039 17H18.2004C19.5259 17 20.6004 15.9255 20.6004 14.6V11M17.1842 1.40002L20.6004 5.00002M20.6004 5.00002L17.1842 8.60002M20.6004 5.00002L3.80039 5.00002C2.47491 5.00002 1.40039 6.07454 1.40039 7.40002L1.40039 11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MenuComponent = ({open, anchorEl, handleClose}: IMenu) => {
  const {getFirstLetter} = useGetFirstLetter()
  const {onSignOut, userData, handleOpenModal, lotacaoSelecionada} = useAuth()
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      disableScrollLock={true}>
      <Box className="flex flex-col min-w-[320px] gap-[10px]">
        <Box
          onClick={handleClose}
          className="px-[20px] hover:bg-slate-200 hover:cursor-pointer py-[10px] bg-[#F3F4F6]">
          <Box className="flex gap-[10px] items-center">
            <Box className="bg-[#FCB900] rounded-[50px] p-[12px] font-bold text-white text-[12px]">
              {getFirstLetter(userData.name)}
            </Box>
            <div className="flex flex-col">
              <p className="text-[12px] text-black font-bold">
                {userData.name.toUpperCase()}
              </p>
              <p className="text-[10px] text-black break-words max-w-[300px]">
                {lotacaoSelecionada?.nomeEntidade} -{' '}
                {lotacaoSelecionada?.funcao}
              </p>
            </div>
          </Box>
        </Box>
        {/* <Box
          onClick={handleClose}
          className="flex items-center hover:bg-slate-200 hover:cursor-pointer py-[10px] gap-[10px] px-[20px]">
          <BsPerson size={30} />
          <p>Meu Perfil</p>
        </Box> */}

        <Box
          onClick={() => {
            handleClose()
            handleOpenModal()
          }}
          className="flex items-center hover:bg-slate-200 hover:cursor-pointer py-[10px] gap-[10px] px-[20px]">
          <IconChange color={'#000'} />
          <p>Trocar de GRE/Escola</p>
        </Box>

        <Box
          onClick={() => {
            onSignOut()
            handleClose()
          }}
          className="flex items-center hover:bg-slate-200 hover:cursor-pointer py-[10px] gap-[10px] px-[20px]">
          <TbLogout2 size={30} />
          <p>Sair</p>
        </Box>
      </Box>
    </Menu>
  )
}

export default MenuComponent
