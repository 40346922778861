import {Box} from '@mui/material'
import {BsTwitterX} from 'react-icons/bs'
import {FaFacebook, FaInstagram, FaYoutube} from 'react-icons/fa'
import {PiBooksLight} from 'react-icons/pi'

const Footer = () => {
  return (
    <Box className="bg-[#004394] w-full h-[235px] flex flex-wrap justify-center min-[800px]:gap-[132px] max-[800px]:gap-[30px] p-[20px] items-center">
      <img
        src="/assets/logo-governo-branco.svg"
        width={360}
        height={92}
        alt="Logo governo branco"
      />
      <Box className="flex flex-wrap gap-[32px] items-center">
        <a
          href="https://drive.google.com/file/d/15LnJ4F15WBjFEROR36W338WTtRtPTw2C/view?usp=sharing"
          target="_blank"
          className="flex gap-[10px] items-center text-white"
          rel="noreferrer">
          <PiBooksLight size={24} color="white" /> Guia de Acesso
        </a>
      </Box>
    </Box>
  )
}

export default Footer
